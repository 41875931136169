import { useState } from 'react';
import { Button, Table, Tag } from 'antd';

import { BgColorsOutlined, CoffeeOutlined, EuroOutlined } from '@ant-design/icons';
import { generate, green, presetPalettes, red } from '@ant-design/colors';
import { ColorPicker, theme } from 'antd';

import { LOCAL_WAITER_EARN_COLOR, LOCAL_WAITER_PRODUCTION_COLOR, TAG_COLORS_PALETTE } from '../../helpers/Globals';
import formatEuro from '../../helpers/FormatEuro';
import { render } from '@testing-library/react';

const genPresets = (presets = presetPalettes) =>
  Object.entries(presets).map(([label, colors]) => ({
    label,
    colors,
  }));

const TableWaiterProductions = (props) => {

  const [productionColor, setProductionColor] = useState(localStorage.getItem(LOCAL_WAITER_PRODUCTION_COLOR) ? localStorage.getItem(LOCAL_WAITER_PRODUCTION_COLOR) : "warning");
  const [earnColor, setEarnColor] = useState(localStorage.getItem(LOCAL_WAITER_EARN_COLOR) ? localStorage.getItem(LOCAL_WAITER_EARN_COLOR) : "green");

  var parentData = JSON.parse(JSON.stringify(props.data))
  
  var waiterFilter = [];

  const { token } = theme.useToken();
  const presets = genPresets({
    primary: generate(token.colorPrimary),
    red,
    green,
  });

  for (const i of parentData) {
    if(waiterFilter.find(element => element.text === i.waiter_name) === undefined){
      waiterFilter.push({       
        "text": i.waiter_name,
        "value":i.waiter_name,
      });
    };

    delete i.children;
  }
  
  const saveProductionColor = (value) => {
    setProductionColor(value);
    localStorage.setItem(LOCAL_WAITER_PRODUCTION_COLOR, value)
  };
  
  const saveEarnColor = (value) => {
    setEarnColor(value);
    localStorage.setItem(LOCAL_WAITER_EARN_COLOR, value)
  };
  
  const columns = [
    {
      title: 'Cameriere',
      dataIndex: 'waiter_name',
      filters: waiterFilter,
      render:(value) => value == "" ? "Operatore Cassa" : value,
      onFilter: (value, record) => record.waiter_name.indexOf(value) === 0,
    },
    {
      title: () => {
        return <div className='flex align-center gap-5'>
          {"Totale produzione"}
          <ColorPicker
            onChangeComplete={(value)=>saveProductionColor(value.toHexString())}
            defaultValue={productionColor}
            value={productionColor}
            presets={presets}
            format={"hex"}
            size='small'
          />
          <Button
            size='small'
            icon={<BgColorsOutlined />}
            onClick={()=>saveProductionColor(TAG_COLORS_PALETTE[Math.floor(Math.random()*TAG_COLORS_PALETTE.length)]) }
          />
        </div>
      },
      dataIndex: 'waiter_produced',
      render: (value)=> <Tag className='fs-15' icon={<CoffeeOutlined />} bordered={false} color={productionColor}>{formatEuro(value) + " €"}</Tag>,
    },
    {
       title: () => {
        return <div className='flex align-center gap-5'>
          {"Totale incassato"}
          <ColorPicker
            onChangeComplete={(value)=>saveEarnColor(value.toHexString())}
            defaultValue={earnColor}
            presets={presets}
            value={earnColor}
            format={"hex"}
            size='small'
          />
          <Button
            size='small'
            icon={<BgColorsOutlined />}
            onClick={()=>saveEarnColor(TAG_COLORS_PALETTE[Math.floor(Math.random()*TAG_COLORS_PALETTE.length)]) }
          />
        </div>
      },
      dataIndex: 'waiter_amount',
      render: (value) => <Tag className='fs-15' icon={<EuroOutlined />} bordered={false} color={earnColor}>{formatEuro(value) + " €"}</Tag>,
    },
  ];


  return (
    <>
        <Table
          columns={columns}
          pagination={{
            defaultPageSize:"26",
          }}
          className="mobile-x-scroll"
          expandable={{
            expandedRowRender: (record) => {
              var children = props.data[props.data.findIndex(item => item.key === record.key)].products;
              const columns = [
                {
                  title: 'Prodotto',
                  dataIndex: 'product_description',
                  key: 'product_description',
                },
                {
                  title: 'Quantità',
                  dataIndex: 'quantity',
                  render: (value)=>value + " pz.",
                  key: 'quantity',
                },
                {
                  title: 'Totale',
                  dataIndex: 'amount',
                  render: (value)=>formatEuro(value) + " €",
                  key: 'amount',
                },
              ];
              return(
                <Table
                  className='di-table-nested'
                  columns={columns}
                  dataSource={children}
                  pagination={false}
                  bordered
                /> 
              )
          }
          }}
          dataSource={parentData}
          bordered
        />
    </>
  );
};
export default TableWaiterProductions;