import { useContext, useState } from 'react';

import { MenuOutlined, MoonFilled, SunFilled } from '@ant-design/icons';
import { Layout, Drawer, FloatButton } from 'antd';

import FiltersDrawer from './FiltersDrawer.js';
import { Context } from '../helpers/Context';
import DashboardMenu from './DashboardMenu';
import Logo from './Logo.js';
import DiScrollspy from './DiScrollspy.js';
import Version from './Version.js';

const { Content, Sider } = Layout;

function DashboardLayout({ children, hideShops }) {
  
  const [open, setOpen] = useState(false);

  const { changeDarkMode, darkMode, refDarkMode } = useContext(Context);

  const showDrawer = () => {
    document.querySelector("body").classList.toggle("vw-100");
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  const {shopName} = useContext(Context);

  return (
    
    <Layout
      className='di-dashboard'
        style={{
          minHeight: '100vh',
        }}
      > 

        {/* header e drawer per mobile */}
        <section className='di-header mobile-show flex space-between align-center'>
          <div className='di-hedaer-left'>
            <MenuOutlined onClick={showDrawer} className="di-show-drawer mobile-show"/>
          </div>        
          <div className='di-hedaer-center'>
          </div>        
          <div className='di-hedaer-right'>
            <div className='di-logo-wrapper flex align-end column w-100 p-10'>
              <Logo white={darkMode}/>
              {
                shopName && <marquee className='shop-name'>{shopName}</marquee>
              }
            </div>
          </div>      
        </section>
        <Drawer className="di-mobile-menu" placement="left" width={"260px"} onClose={onClose} open={open}>
          <DashboardMenu hideShops={hideShops} /> 
          <Version/>
        </Drawer>
        {/* drawer per mobile */}

        {/* sidebar desktop */}
        <Sider 
          theme='light'
          className='di-sidebar mobile-hide'
          onBreakpoint={(broken) => {
            console.log(broken);
          }}
          onCollapse={(collapsed, type) => {
            console.log(collapsed, type);
          }}
        >    
          <div className='di-logo-wrapper flex w-100 gap-10 p-10'>
            <Logo white={darkMode}/>
          </div>
          <DashboardMenu hideShops={hideShops} />
          <Version />
        
        </Sider>
        {/* sidebar desktop */}

        {/* conent del layout dinamico */}
        <Content>
            {children}
        </Content>
      {/* conent del layout dinamico */}
      
      {/* FAB per cambiare il tema da chiaro a scuro */}
        <FloatButton
          ref={refDarkMode}
          icon={darkMode ? <MoonFilled /> : <SunFilled/>}
          className="mb-20"
          onClick={()=>changeDarkMode(!darkMode)}
        />
      {/* FAB per cambiare il tema da chiaro a scuro */}

      <FiltersDrawer />
      
    </Layout>
  )
}

export default DashboardLayout;