import React from 'react'

import { QuestionCircleOutlined } from '@ant-design/icons'
import { Button, Tooltip } from 'antd'

import { PRIMARY_COLOR } from '../helpers/Globals'

export default function DiTooltip({text}) {
  return (
   <Tooltip color={PRIMARY_COLOR} className="cursor-pointer di-chart-tooltip fs-16 opacity-05" title={text}>
      <QuestionCircleOutlined/>
   </Tooltip>
  )
}
