import { Table, Tag } from 'antd';
import formatEuro from '../../helpers/FormatEuro';

const TableSelledCategories = (props) => {

  var parentData = JSON.parse(JSON.stringify(props.data))
  var categoriesFilter = [];

  for (const i of parentData) {
    if(categoriesFilter.find(element => element.text === i.category_description) === undefined){
      categoriesFilter.push({       
        "text": i.category_description,
        "value":i.category_description,
      });
    };

    delete i.products;
  }
  
  const columns = [
    {
      title: 'Categoria',
      dataIndex: 'category_description',
      filters: categoriesFilter,
      onFilter: (value, record) => record.category_description.indexOf(value) === 0,
      // sorter: (a, b) => a.category_description.length - b.category_description.length,
    },
    {
      title: 'Codice',
      dataIndex: 'category_code',
      sortDirections: ['ascend', 'descend','ascend']
    },
    {
      title: 'Totale',
      dataIndex: 'amount',
      render: (value)=><Tag className='fs-12' bordered={false} color='green'>{formatEuro(value) + " €"}</Tag>,
      sorter: (a, b) => a.amount - b.amount,
      sortDirections: ['ascend', 'descend','ascend']
    },
  ];

  

  return (
    <>
        <Table
          columns={columns}
          pagination={{
            defaultPageSize:"26",
          }}
          className="mobile-x-scroll"
          expandable={{
            expandedRowRender: (record) => {
              var children = props.data[props.data.findIndex(item => item.key === record.key)].products;
              const columns = [
                {
                  title: 'Prodotto',
                  dataIndex: 'product_description',
                  key: 'product_description',
                },
                {
                  title: 'Quantità',
                  dataIndex: 'quantity',
                  render: (value)=>value + " pz.",
                  key: 'quantity',
                },
                {
                  title: 'Totale',
                  dataIndex: 'amount',
                  render: (value)=> formatEuro(value) + " €",
                  key: 'amount',
                },
              ];
              return(
                <Table
                  className='di-table-nested'
                  columns={columns}
                  dataSource={children}
                  pagination={false}
                  bordered
                /> 
              )
          }
          }}
          dataSource={parentData}
          bordered
        />
    </>
  );
};
export default TableSelledCategories;