import React, { useContext, useEffect, useState } from 'react'

import dayjs from 'dayjs';

import { ArrowDownOutlined, ArrowUpOutlined, CalendarOutlined } from '@ant-design/icons';
import { Button, Col, DatePicker, Divider, InputNumber, Popover, Row, Skeleton, Spin, Statistic, Switch } from 'antd'
import * as Unicons from '@iconscout/react-unicons';

import { ENDPOINT_PERIOD_SALES, LOCAL_FAST_AVERAGE_RECEIPT_COMPARE_1, LOCAL_FAST_AVERAGE_RECEIPT_COMPARE_2, LOCAL_FAST_AVERAGE_RECEIPT_COMPARE_3, PRIMARY_COLOR, TOOLTIP_DIFF_TEXT } from '../../../helpers/Globals';
import safeNanDivision from '../../../helpers/SafeNanDivision';
import getPercentage from '../../../helpers/GetPercentage';
import { getOldDate,} from '../../../helpers/DateUtils';
import formatEuro from '../../../helpers/FormatEuro';
import { Context } from '../../../helpers/Context';
import { ApiCall } from '../../../Backoffice';


export default function DisplayFastDailyAverageReceipt() {
   

   const [showAverageReceiptPercentage, setShowAverageReceiptPercentage] = useState(false);

   const [dailyEarnOldDay3, setDailyEarnOldDay3] = useState(null);
   const [dailyEarnOldDay1, setDailyEarnOldDay1] = useState(null);
   const [dailyEarnOldDay2, setDailyEarnOldDay2] = useState(null); 
   const [dailyEarn, setDailyEarn] = useState(0); //totale guadagno nel giorno ordierno
   
   //settaggi per gli scontrini chiusi
   const [dailyReceiptOldDay3, setDailyReceiptOldDay3] = useState(null);
   const [dailyReceiptOldDay1, setDailyReceiptOldDay1] = useState(null); 
   const [dailyReceiptOldDay2, setDailyReceiptOldDay2] = useState(null);
   const [dailyCustomers, setDailyCustomers] = useState(null);
   const [dailyReceipt, setDailyReceipt] = useState(null);
  
  const [loadingDiff1, setLoadingDiff1] = useState(true); 
  const [loadingDiff2, setLoadingDiff2] = useState(true); 
  const [loadingDiff3, setLoadingDiff3] = useState(true); 

  // --------- setto i giorni di deafult per le statistiche rapide e personalizzabili ---------
  const [oldDay1, setOldDay1] = useState(localStorage.getItem(LOCAL_FAST_AVERAGE_RECEIPT_COMPARE_1) ? localStorage.getItem(LOCAL_FAST_AVERAGE_RECEIPT_COMPARE_1) : 1); 
  const [oldDay2, setOldDay2] = useState(localStorage.getItem(LOCAL_FAST_AVERAGE_RECEIPT_COMPARE_2) ? localStorage.getItem(LOCAL_FAST_AVERAGE_RECEIPT_COMPARE_2) : 7); 
  const [oldDay3, setOldDay3] = useState(localStorage.getItem(LOCAL_FAST_AVERAGE_RECEIPT_COMPARE_3) ? localStorage.getItem(LOCAL_FAST_AVERAGE_RECEIPT_COMPARE_3) : 28); 
  // --------- setto i giorni di deafult per le statistiche rapide e personalizzabili ---------

   
// ---- variabili dal context -----
  const {
  setDailySelledProductTotal,
  dailySelledProductTotal,
  commonQueryParams,
  refreshVariables,
  useCurrentTime,
  dailyDiscount,
  accessToken,
  isRestaurant,
  userType,
} = useContext(Context);
// ---- variabili dal context -----
   
     
      // ---------------------  chiamata giornaliera per ricevere i dati delle ${ENDPOINT_PERIOD_SALES} da cui ottengo il totale venduto giornaliero, il totale clienti serviti, prodotti venduti e documenti emessi --------------------- 
      useEffect(()=>{

        (async () => {
          const json = await ApiCall(`${ENDPOINT_PERIOD_SALES}?date=${dayjs().format('YYYY-MM-DDT00:00:00')}&end_date=${dayjs().format('YYYY-MM-DDT23:59:59')}&${commonQueryParams}`, "GET", `${accessToken}`, null)
         
          
          if(json){
            var sales = json.sales
            var dailyEarn = 0; 
            var dailyCustomers = 0;
            var selledProductTotal = 0;

            for (const i in sales) {
             
              //sommo i totali delle singole battute 
              for (let n in sales[i]["lines"]) {
                dailyEarn = dailyEarn + sales[i]["lines"][n]["amount"];
                selledProductTotal = selledProductTotal + sales[i]["lines"][n]["quantity"];

                 //controllo di tutti i prodotti venduti se c'è un prodotto di tipo coperto e la sua quantità incrementa i clienti serviti
                 if(sales[i]["lines"][n]["is_cover"] === 1){
                  dailyCustomers += sales[i]["lines"][n]["quantity"];
                }
              }
            }
            if(isRestaurant)
              setDailyEarn(dailyEarn.toFixed(2) - dailyDiscount);
            else {
              setDailyEarn(dailyEarn.toFixed(2));
            }
            setDailyCustomers(dailyCustomers);
            setDailyReceipt(sales.length);
            setDailySelledProductTotal(selledProductTotal);
          }
        })(); 
      },[...refreshVariables]);

  // ---------------------  chiamata per ricevere le statistiche rapide per il primo valore --------------------- 
  useEffect(() => {

    if (dailyEarn === null || dailyReceipt === null) return;
    
    setLoadingDiff1(true);

    var fullOldDate = getOldDate(oldDay1);
    var oldDate = fullOldDate.split("T",1)[0];

    if(!useCurrentTime) fullOldDate = oldDate+"T23:59:59"; //se il filtro useCurrentTime è disattivo prendo i dati dei giorni precedenti di tutta la giornata, altrimenti confronto i dati fino all'ora corrente di oggi


    (async () => {
      const json = await ApiCall(`${ENDPOINT_PERIOD_SALES}?date=${oldDate+"T00:00:00"}&end_date=${fullOldDate}&${commonQueryParams}`, "GET", `${accessToken}`, null)
      
      if(json){
        var sales = json.sales
        var dailyEarnOldDay1Temp = 0; 
        
        for (const i in sales) {
          //sommo i totali delle singole battute 
          for (let n in sales[i]["lines"]) {
            dailyEarnOldDay1Temp += sales[i]["lines"][n]["amount"];
          }
        }
        if (dailyEarnOldDay1Temp == dailyEarnOldDay1 || sales.length == dailyReceiptOldDay1) setLoadingDiff1(false);
        setDailyEarnOldDay1(dailyEarnOldDay1Temp.toFixed(2));
        setDailyReceiptOldDay1(sales.length);
      }
    })(); 
  
  }, [...refreshVariables, useCurrentTime, dailyEarn, dailyReceipt, oldDay1]);
  // ---------------------  chiamata per ricevere le statistiche rapide per il primo valore --------------------- 

  // ---------------------  chiamata per ricevere le statistiche rapide per il secondo valore --------------------- 
  useEffect(() => {
    if (dailyEarn === null || dailyReceipt === null) return;

    setLoadingDiff2(true);
    

    var fullOldDate = getOldDate(oldDay2);
    var oldDate = fullOldDate.split("T",1)[0];

    if(!useCurrentTime) fullOldDate = oldDate+"T23:59:59"; //se il filtro useCurrentTime è disattivo prendo i dati dei giorni precedenti di tutta la giornata, altrimenti confronto i dati fino all'ora corrente di oggi

    (async () => {
      const json = await ApiCall(`${ENDPOINT_PERIOD_SALES}?date=${oldDate+"T00:00:00"}&end_date=${fullOldDate}&${commonQueryParams}`, "GET", `${accessToken}`, null)
      if(json){
        var sales = json.sales
        var dailyEarnOldDay2Temp = 0; 

        for (const i in sales) {
          //sommo i totali delle singole battute 
          for (let n in sales[i]["lines"]) {
            dailyEarnOldDay2Temp += sales[i]["lines"][n]["amount"];
          }
        }
        if (dailyEarnOldDay2Temp == dailyEarnOldDay2 || sales.length == dailyReceiptOldDay2) setLoadingDiff2(false);
        setDailyEarnOldDay2(dailyEarnOldDay2Temp.toFixed(2));
        setDailyReceiptOldDay2(sales.length);
      }
    })(); 

  }, [...refreshVariables, useCurrentTime,dailyEarn,dailyReceipt, oldDay2]);
    // ---------------------  chiamata per ricevere le statistiche rapide per il secondo valore --------------------- 


  // ---------------------  chiamata per ricevere le statistiche rapide per il terzo valore --------------------- 
  useEffect(() => {
    if (dailyEarn === null || dailyReceipt === null) return;

    setLoadingDiff3(true);

    var fullOldDate = getOldDate(oldDay3);
    var oldDate = fullOldDate.split("T",1)[0];

    if(!useCurrentTime) fullOldDate = oldDate+"T23:59:59"; //se il filtro useCurrentTime è disattivo prendo i dati dei giorni precedenti di tutta la giornata, altrimenti confronto i dati fino all'ora corrente di oggi

    (async () => {
      const json = await ApiCall(`${ENDPOINT_PERIOD_SALES}?date=${oldDate+"T00:00:00"}&end_date=${fullOldDate}&${commonQueryParams}`, "GET", `${accessToken}`, null)
      
      if(json){
        var sales = json.sales
        var dailyEarnOldDay3Temp = 0; 

        for (const i in sales) {
          //sommo i totali delle singole battute 
          for (let n in sales[i]["lines"]) {
            dailyEarnOldDay3Temp += sales[i]["lines"][n]["amount"];
          }
        }
        if (dailyEarnOldDay3Temp == dailyEarnOldDay3 || sales.length == dailyReceiptOldDay3) setLoadingDiff3(false);
        setDailyEarnOldDay3(dailyEarnOldDay3Temp.toFixed(2));
        setDailyReceiptOldDay3(sales.length);
      }
    })(); 
  }, [...refreshVariables, useCurrentTime, dailyEarn, dailyReceipt, oldDay3]);
  // ---------------------  chiamata per ricevere le statistiche rapide per il terzo valore --------------------- 

 // ---------------------  gestione dei loader dati --------------------- 
  useEffect(() => {
    if (dailyEarnOldDay1 === null || dailyReceipt === null || dailyReceiptOldDay1 === null || dailyEarn == null) return;
    setLoadingDiff1(false);
  },[dailyEarn,dailyEarnOldDay1, dailyReceipt,dailyReceiptOldDay1,])

  useEffect(() => {
    if (dailyEarnOldDay2 === null || dailyReceipt === null || dailyReceiptOldDay2 === null || dailyEarn == null) return;
    setLoadingDiff2(false);
  },[dailyEarn,dailyEarnOldDay2, dailyReceipt,dailyReceiptOldDay2,])

  useEffect(() => {
    if (dailyEarnOldDay3 === null || dailyReceipt === null || dailyReceiptOldDay3 === null || dailyEarn == null) return;
    setLoadingDiff3(false);
  },[dailyEarn,dailyEarnOldDay3, dailyReceipt,dailyReceiptOldDay3,])
  // ---------------------  gestione dei loader dati --------------------- 

  

  // ----------- contenuto del popover per cambiare le comparazioni -----------

  const [showCalendar, setShowCalendar] = useState(true);
  const [popoverOldDay1, setPopoverOldDay1] = useState(1); 
  const [popoverOldDay2, setPopoverOldDay2] = useState(1); 
  const [popoverOldDay3, setPopoverOldDay3] = useState(1); 
  
  const popoverContent = (param) => (
    <div>
      <p>{TOOLTIP_DIFF_TEXT}</p>
      <div className='flex gap-5 align-center'>
          {!showCalendar ? <InputNumber
            min={1}
            prefix="-"
            defaultValue={
              param === 1 ? oldDay1 :
              param === 2 ? oldDay2 :
              param === 3 ? oldDay3 :
              1
            }
            onChange={(value) => {
              switch (param) {
                case 1:
                  setPopoverOldDay1(value)
                  break;
                case 2:
                  setPopoverOldDay2(value)
                  break;
                case 3:
                  setPopoverOldDay3(value)
                  break;
              
                default:
                  break;
              }
            }
            }
            changeOnWheel
          />
          :
          <DatePicker
            size='medium'
            placeholder='Scegli giorno'
            onChange={(value) => {
                var day = dayjs(value).diff(dayjs(), "day", true).toString().split(".")[0].replace("-","");
                if (day === "un") day = 1;
                switch (param) {
                  case 1:
                    setPopoverOldDay1(day)
                    break;
                  case 2:
                    setPopoverOldDay2(day)
                    break;
                  case 3:
                    setPopoverOldDay3(day)
                    break;
                
                  default:
                    break;
                }
              }
            }
          />}
          <Button
            type='primary'
            size='medium'
            onClick={() => {
              
              localStorage.setItem(
                param === 1 ? LOCAL_FAST_AVERAGE_RECEIPT_COMPARE_1 :
                param === 2 ? LOCAL_FAST_AVERAGE_RECEIPT_COMPARE_2 :
                param === 3 ? LOCAL_FAST_AVERAGE_RECEIPT_COMPARE_3 :
                "",
                param === 1 ? popoverOldDay1 :
                param === 2 ? popoverOldDay2 :
                param === 3 ? popoverOldDay3 :
                ""
              )

                switch (param) {
                case 1:
                  setOldDay1(popoverOldDay1)
                  break;
                case 2:
                  setOldDay2(popoverOldDay2)
                  break;
                case 3:
                  setOldDay3(popoverOldDay3)
                  break;
              
                default:
                  break;
                }
              
            }}
          >
            Salva
        </Button>
        <Switch
          checkedChildren="n"
          unCheckedChildren={<CalendarOutlined />}
          onChange={()=>setShowCalendar(!showCalendar)}
        />
      </div>
    </div>
  );
  // ----------- contenuto del popover per cambiare le comparazioni ----------- 

  
  return (
      <div className="di-card h-100">
      <Spin className='absolute bottom-20 right-30' spinning={loadingDiff1 || loadingDiff2 || loadingDiff3} />
      <Row gutter={[15, 15]} justify="center" align="middle">
        
            <Unicons.UilReceiptAlt className="color-primary" />
        
            <Col align="center">
            <div className='fs-18 fw-5'>Documento medio</div>
            </Col>
      
            <Col align="center">
            <div className='flex gap-5 align-center'>
               <Switch
                  checkedChildren="%"
                  unCheckedChildren="€"
                  onChange={()=>setShowAverageReceiptPercentage(showAverageReceiptPercentage ? false : true)}
               />
            </div>
            </Col>
      
         </Row>
         <Divider className='mt-10 mb-10' />
  
          <Row gutter={[15, 15]} justify="center" align="middle">
            <Col xs={24} xl={6} align="center" className='fs-30'>
              {dailyEarn === null || dailyReceipt === null ?
                  <Skeleton.Button active/>
                :
                  <>
                    {formatEuro(safeNanDivision(dailyEarn,dailyReceipt))} € 
                    <div className='flex column fs-12 opacity-07'>Media prodotti per documento: {safeNanDivision(dailySelledProductTotal,dailyReceipt).toFixed(2)}</div>
                    {isRestaurant &&
                     <>
                        <div className='flex column fs-12 opacity-07'>Media clienti per documento: {safeNanDivision(dailyCustomers, dailyReceipt).toFixed(2)}</div>
                        <div className='flex column fs-12 opacity-07'>Media incasso per coperto: {safeNanDivision(dailyEarn, dailyCustomers).toFixed(2)}€</div>
                     </>
                    }
                  </>
              }   
            </Col>
          </Row>
      
         <Divider className='mt-10 mb-10' />
         <Row justify="center"><div className='fs-12 color-grey'>{showAverageReceiptPercentage ? "Confronti" : "Totali" }</div></Row>
         <Row gutter={[15, 15]} justify="space-around" align="middle">
            
            <Popover
              className='cursor-pointer'
              content={()=>popoverContent(1)}
              trigger={"click"}
            >
              <Col align="center">
              {showAverageReceiptPercentage?
                 <Statistic
                    groupSeparator="."
                    decimalSeparator=','
                    title={`-${oldDay1}gg`}
                    loading={loadingDiff1}
                    value={getPercentage(safeNanDivision(dailyEarn,dailyReceipt), safeNanDivision(dailyEarnOldDay1,dailyReceiptOldDay1))}
                    valueStyle={{
                    color: safeNanDivision(dailyEarnOldDay1,dailyReceiptOldDay1) < safeNanDivision(dailyEarn,dailyReceipt) ?'#3f8600':'#cf1322',
                    }}
                    prefix={ 
                    safeNanDivision(dailyEarnOldDay1,dailyReceiptOldDay1) < safeNanDivision(dailyEarn,dailyReceipt) ? <ArrowUpOutlined />
                    :
                    safeNanDivision(dailyEarnOldDay1,dailyReceiptOldDay1) > safeNanDivision(dailyEarn,dailyReceipt) ? <ArrowDownOutlined />
                    :
                    ""
                    }
                    suffix="%"
                 />
                 :
                 <Statistic
                    groupSeparator="."
                    decimalSeparator=','
                    title={`-${oldDay1}gg`}
                    loading={loadingDiff1}
                    value={formatEuro(safeNanDivision(dailyEarnOldDay1,dailyReceiptOldDay1))}
                    suffix={"€"}
                    valueStyle={{color: PRIMARY_COLOR}}
                 />
              }
              </Col>
            </Popover>
            
            <Popover
              className='cursor-pointer'
              content={()=>popoverContent(2)}
              trigger={"click"}
            >
              <Col align="center">
              {showAverageReceiptPercentage?
                 <Statistic
                    groupSeparator="."
                    decimalSeparator=','
                    title={`-${oldDay2}gg`}
                    loading={loadingDiff2}
                    value={getPercentage(safeNanDivision(dailyEarn,dailyReceipt), safeNanDivision(dailyEarnOldDay2,dailyReceiptOldDay2))}
                    valueStyle={{
                    color: (safeNanDivision(dailyEarnOldDay2,dailyReceiptOldDay2)) < safeNanDivision(dailyEarn,dailyReceipt) ?'#3f8600':'#cf1322',
                    }}
                    prefix={ 
                    safeNanDivision(dailyEarnOldDay2,dailyReceiptOldDay2) < safeNanDivision(dailyEarn,dailyReceipt) ? <ArrowUpOutlined />
                    :
                    safeNanDivision(dailyEarnOldDay2,dailyReceiptOldDay2) > safeNanDivision(dailyEarn,dailyReceipt) ? <ArrowDownOutlined />
                    :
                    ""
                    }
                    suffix="%"
                 />
                 :
                 <Statistic
                    groupSeparator="."
                    decimalSeparator=','
                    title={`-${oldDay2}gg`}
                    loading={loadingDiff2}
                    value={formatEuro(safeNanDivision(dailyEarnOldDay2,dailyReceiptOldDay2))}
                    suffix={"€"}
                    valueStyle={{color: PRIMARY_COLOR}}
                 />
              }
              </Col>
            </Popover>
            
            <Popover
              className='cursor-pointer'
              content={()=>popoverContent(3)}
              trigger={"click"}
            >
              <Col align="center">
              {showAverageReceiptPercentage?
                 <Statistic
                    groupSeparator="."
                    decimalSeparator=','
                    title={`-${oldDay3}gg`}
                    loading={loadingDiff3}
                    value={getPercentage(safeNanDivision(dailyEarn,dailyReceipt), safeNanDivision(dailyEarnOldDay3,dailyReceiptOldDay3))}
                    valueStyle={{
                    color: safeNanDivision(dailyEarnOldDay3,dailyReceiptOldDay3) < safeNanDivision(dailyEarn,dailyReceipt) ?'#3f8600':'#cf1322',
                    }}
                    prefix={ 
                    safeNanDivision(dailyEarnOldDay3,dailyReceiptOldDay3) < safeNanDivision(dailyEarn,dailyReceipt) ? <ArrowUpOutlined />
                    :
                    safeNanDivision(dailyEarnOldDay3,dailyReceiptOldDay3) > safeNanDivision(dailyEarn,dailyReceipt) ? <ArrowDownOutlined />
                    :
                    ""
                    }
                    suffix="%"
                 />
                 :
                 <Statistic
                    groupSeparator="."
                    decimalSeparator=','
                    title={`-${oldDay3}gg`}
                    loading={loadingDiff3}
                    value={formatEuro(safeNanDivision(dailyEarnOldDay3,dailyReceiptOldDay3))}
                    suffix={"€"}
                    valueStyle={{color: PRIMARY_COLOR}}
                 />
              }
              </Col>
            </Popover>
         </Row>
      </div>
  )
}
