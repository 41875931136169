
//questa funzione fa una semplice divisione ma se uno dei due parametri è 0 ritorna  0 invece di infinity/NaN, utilizzata nel calcolare le medie

export default function safeNanDivision(num1, num2) {
  if(num1 === 0){
    return 0
  }if(num2 === 0){
    return 0
  }else{
    return num1 / num2
  }
}