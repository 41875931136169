const endpoint = "https://insight.dylogapp.it/api/dy/ins/v1";

const ApiCall = async (path, method, auth, body) => {
  
  try{
    
    const response = 
      await fetch(
        `${endpoint}/${path}`,
        {
          method: method,
          headers: {
            'Authorization': auth
          }
        }
      );
      
    
    if(response.status === 200){
      const json = await response.json();

      if(json.Result === "OK"){
        return json.Json;    
      }
      console.log(json.Message)
    }

  }catch(_){}
  
  return null;
}

export {
  endpoint, ApiCall
}