import { useContext } from 'react';

import { ResponsiveBar } from '@nivo/bar'
import { Context } from '../../helpers/Context';
import { DARK_BG_CONTAINER } from '../../helpers/Globals';

const BarGrouped = ({data, xIndex, yIndex, layout, margin, colors, suffix, grouped}) => {

    const { darkMode } = useContext(Context);
    
    var indexKeys = [];
    for (const key in data) {
      Object.keys(data[key]).forEach(e => {
        if(e !== xIndex && indexKeys.findIndex(i => i === e) === -1){
            indexKeys.push(e);
        }
      });
    }
    
    return (
       <div className='di-chart-wrapper'>
         <div className='di-chart' style={{minHeigth:"600px", height: data.length + 50 + "0px"}}>
            <ResponsiveBar
                data={data}
                keys={indexKeys}
                indexBy={xIndex}
                margin={margin}
                padding={0.3}
                layout={layout}
                valueFormat={v => !suffix ? v : v + suffix }
                groupMode={!grouped ? "stacked" : "grouped"}
                valueScale={{ type: 'linear' }}
                indexScale={{ type: 'band', round: true }}
                colors={{scheme: !colors ? 'nivo' : colors}}
                defs={[
                    {
                        id: 'dots',
                        type: 'patternDots',
                        background: 'inherit',
                        color: '#38bcb2',
                        size: 4,
                        padding: 1,
                        stagger: true
                    },
                    {
                        id: 'lines',
                        type: 'patternLines',
                        background: 'inherit',
                        color: '#eed312',
                        rotation: -45,
                        lineWidth: 6,
                        spacing: 10
                    }
                ]}
                fill={[
                    {
                        match: {
                            id: 'fries'
                        },
                        id: 'dots'
                    },
                    {
                        match: {
                            id: 'sandwich'
                        },
                        id: 'lines'
                    }
                ]}
                borderColor={{
                    from: 'color',
                    modifiers: [
                        [
                            'darker',
                            1.6
                        ]
                    ]
                }}
                axisTop={null}
                axisRight={null}
                axisBottom={
                window.innerWidth < 1000 ?
                    null
                :
                    {
                        tickSize: 5,
                        tickPadding: 5,
                        tickRotation: 0,
                        legend: xIndex,
                        legendPosition: 'middle',
                        legendOffset: 32,
                    }
                }
                axisLeft={{
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                    legendPosition: 'middle',
                    legendOffset: -40,
                }}

                labelSkipWidth={12}
                labelSkipHeight={12}
                labelTextColor={{
                    from: 'color',
                    modifiers: [
                        [
                            'darker',
                            1.6
                        ]
                    ]
                }}
                //   legends={[
                //       {
                //           dataFrom: 'keys',
                //           anchor: 'top-left',
                //           direction: 'row',
                //           justify: false,
                //           translateX: -40,
                //           translateY: -40,
                //           itemsSpacing: 2,
                //           itemWidth: 100,
                //           itemHeight: 20,
                //           itemDirection: 'left-to-right',
                //           itemOpacity: 0.85,
                //           symbolSize: 20,
                //           effects: [
                //               {
                //                   on: 'hover',
                //                   style: {
                //                       itemOpacity: 1
                //                   }
                //               }
                //           ]
                //       }
                //   ]}
                role="application"
                    

                theme={{
                    axis: {
                        ticks: {
                            text: {
                                fill:darkMode ? "#fff" : "000"
                            }                                        
                        },
                        legend: {
                            text: {
                                fill:darkMode ? "#fff" : "000"
                            }                                        
                        }
                    },
                    tooltip: {
                        container: {
                            background: darkMode ? DARK_BG_CONTAINER : "#fff",
                        }
                    }
                }}
                    

            />
         </div>
       </div>
    )
};

export default BarGrouped;