import React from 'react'
import BarGrouped from '../charts/BarGrouped';

export default function DisplayComparedData({ data }) {
   
  return (
   <div className='di-chart-wrapper'>
      <div className='di-chart'  style={{minHeigth:"600px", height: data.length + 50 + "0px"}}>
         <BarGrouped
            grouped 
            data={data}
            xIndex="Giorno" 
            layout={window.innerWidth > 1000 ? "vertical" : "horizontal"} 
            margin={window.innerWidth < 1000 ?  { top: 40, right: 10, bottom: 20, left: 80 } : { top: 50, right: 50, bottom: 50, left: 60 }}
         />   
      </div>
   </div> 
  )
}
