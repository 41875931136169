import React, { useState } from 'react'

import { MinusCircleOutlined, PlusCircleOutlined } from '@ant-design/icons'
import { Button } from 'antd'

export default function ShowMore({ children }) {
   
   const [show, setShow] = useState(true);

   const toggleShowMore = () => {
      setShow(!show);
   }

  return (
     <div className='show-more-wrapper'>
        <div className={!show ? "show-more-content show" : "show-more-content"}>{children}</div>
         <div className='w-100 flex justify-center'>
            <Button className='show-more-button' icon={!show ? <MinusCircleOutlined /> : <PlusCircleOutlined />} onClick={toggleShowMore}>Mostra {!show ? "meno" : "tutto"}</Button>
         </div>
     </div>
  )
}
