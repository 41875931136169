
export default function getPercentage(currentDate, oldDate) {

  currentDate = Number(currentDate);
  oldDate = Number(oldDate);
  
  var decreaseValue = currentDate - oldDate;

  if(oldDate === 0){
    return (currentDate * 100).toFixed(2);
  }
  if (currentDate === 0) {
    return (-oldDate * 100).toFixed(2);
  }else{
    return ((decreaseValue / oldDate) * 100).toFixed(2);
  }
}