import React from 'react';

import { Skeleton } from 'antd';


function ChartSkeleton(props) {
  return (
    <div className='chart-skeleton flex column gap-20'>
        <Skeleton.Input active size={30} className='w-100'/>
        <Skeleton.Input active size={30} className='w-20'/>
        <Skeleton.Input active size={30} className='w-50'/>
        <Skeleton.Input active size={30} className='w-10'/>
        <Skeleton.Input active size={30} className='w-80'/>
    </div>  
  );
}

export default ChartSkeleton;