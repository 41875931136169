
import dayjs from 'dayjs';

function getOldDate(day){

  const currentSeconds = dayjs().unix();
  const seconds = day * 86400;

  const diff = currentSeconds - seconds;
  return dayjs.unix(diff).format('YYYY-MM-DDTHH:mm:ss');
};

const disable12MonthsDate = (current, { from }) => {
  if (from) {
    const curMonth = current.year() * 12 + current.month();
    const fromMonth = from.year() * 12 + from.month();
    return Math.abs(fromMonth - curMonth) >= 12;
  }
  return false;
};


var todayStartDate = dayjs().format('YYYY-MM-DDT00:00:00');
var todayEndDate = dayjs().format('YYYY-MM-DDT23:59:59');


const dateHourFormat = "YYYY/MM/DD HH:mm:ss";

//presets per tutti i calendari
  const rangePresets = [
    {
      label: 'Ieri',
      value: [dayjs().add(-1, 'd').startOf("day"), dayjs().add(-1, 'd').endOf("day")],
    },
    {
      label: 'Ultimi 7 giorni',
      value: [dayjs().add(-7, 'd'), dayjs()],
    },
    {
      label: 'Ultimi 14 giorni',
      value: [dayjs().add(-14, 'd'), dayjs()],
    },
    {
      label: 'Ultimi 30 giorni',
      value: [dayjs().add(-30, 'd'), dayjs()],
    },
    {
      label: 'Ultimi 90 giorni',
      value: [dayjs().add(-90, 'd'), dayjs()],
    },
  ];


export {
  dateHourFormat,
  todayStartDate,
  todayEndDate,
  rangePresets,

  disable12MonthsDate,
  getOldDate
}