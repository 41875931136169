import React, { useEffect, useState } from 'react'

import { DownloadOutlined } from '@ant-design/icons';
import { CSVLink } from 'react-csv'
import { Button } from 'antd';


export default function DownloadCsvButton({ dataToDownload, filename }) {
   
   const [csvHeaders, setCsvHeaders] = useState([]);
   useEffect(() => {
      if (dataToDownload.length > 0) {
         
         var keys = [];
         var headers = [];

         for (var row of dataToDownload) {
            var rowKeys = Object.keys(row)
            for (var key of rowKeys) {
              var index = keys.indexOf(key);
               if (index < 0) {
                  keys.push(key);
                   headers.push(
                     {
                        "label":key,
                        "key":key
                     }
                  )
               } 
            }
         };
         console.log(headers)
         setCsvHeaders(headers);
      }
   }, [dataToDownload]);


  return (
      <>
        {
           dataToDownload.length > 1 &&
            <CSVLink
               data={dataToDownload}
               headers={csvHeaders}
               filename={filename + ".csv"}
               separator=';'
            >
               <Button className='animate scaleIn di-secondary-btn' shape="round" icon={<DownloadOutlined />} />
            </CSVLink >
        }
      </>
  )
}
