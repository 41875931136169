import React, { useEffect, useRef, useState } from 'react'

import { BrowserRouter } from "react-router-dom";
import { Routes, Route } from 'react-router';
import { Context } from './helpers/Context';


import { ConfigProvider, notification, theme } from 'antd';
import { UserOutlined } from '@ant-design/icons';


import {
  ENDPOINT_MOVEMENT_TYPE,
  LOCAL_USE_CURRENT_TIME,
  LOCAL_TRANSACTION_TYPE,
  ENDPOINT_WAITER_LIST,
  LIGHT_MOBILE_HEADER,
  DARK_MOBILE_HEADER,
  LOCAL_ACCESS_TOKEN,
  LOCAL_DATA_FILTER,
  DARK_BG_CONTAINER,
  LOCAL_USER_TYPE,
  ENDPOINT_SHOPS,
  DARK_BG_BODY,
  LOCAL_DARK_MODE,
} from './helpers/Globals';

import SingleWaiterData from './components/pages/SingleWaiterData';
import OpenedTables from './components/pages/OpenedTables';
import Operators from './components/pages/Operators';
import PrivateRoutes from './helpers/PrivateRoutes';
import Compare from './components/pages/Compare';
import Page404 from './components/pages/404';
import changeLog from './helpers/ChangeLog';
import { ApiCall } from './Backoffice';
import App from './App';


if(window.location.search){
  var token = window.location.search.split("?")[1].trim();
  var isApp = window.location.search.split("?")[2];
}

var origin = sessionStorage.getItem("__di_origin") ? sessionStorage.getItem("__di_origin") : document.referrer;
isApp = sessionStorage.getItem("__is_app") ? sessionStorage.getItem("__is_app") : isApp;
//if(window.location.host === "localhost:3000") origin = "backend.easyappear.it";

sessionStorage.setItem("__di_origin", origin);

if (isApp === "app") {
  sessionStorage.setItem("__is_app", isApp);
}


export default function RoutesComponent() {
  
  const [api, contextHolder] = notification.useNotification();

  // --------- variabiili globalizzate al progetto con il context --------- 

  const [isRestaurant, setIsRestaurant] = useState(sessionStorage.getItem(LOCAL_USER_TYPE) === "restaurant" ? true : false);                                //---------------- inizializzo la variabile che, una volta renderizzata la dashboard verrà valorizzata dal session storage
  const [accessToken, setAccessToken] = useState(sessionStorage.getItem(LOCAL_ACCESS_TOKEN));                                                               //---------------- token di accesso della sessione odierna con cui si effettuano le chiamate
  const [userType, setUserType] = useState(sessionStorage.getItem(LOCAL_USER_TYPE));                                                                        //---------------- se l'utente è di tipo ristorante o retail
  
  const [darkMode, setDarkMode] = useState(localStorage.getItem(LOCAL_DARK_MODE));

  const [open, setOpen] = useState(false);                                                                                                                  //---------------- apri il menu mobile
  
  const [shopsList, setShopsList] = useState([]);                                                                                                           //---------------- lista dei punti vendita interrogabili dal WS
  const [shopName,setShopName] = useState("");                                                                                                              //---------------- label del negozio che si sta interrogando attualmente
  const [shop, setShop] = useState("");                                                                                                                     //---------------- codice del negozio passato al WS per interrogare i dati di quel punto vendita

  const [transactionList, setTransactionList] = useState([]);                                                                                               //---------------- lista dei tipi di transazioni usate solo per signa
  const [transactionType, setTransactionType] = useState("");                                                                                               //---------------- tipi di transazioni richieste nelle chiamate WS
                                          
  const [changeFilter, setChangeFilter] = useState(0);                                                                                                      //---------------- una variabile che avvisa il dom di aggiornarsi perché sono cambiati i filtri
  
  const [useCurrentTime, setUseCurrentTime] = useState(false);                                                                                              //---------------- variabile che indica al WS di utilizzare l'orario corrente per confrontare i vecchi dati, o l'intera giornata passata
  const [useDataFilter, setUseDataFilter] = useState(localStorage.getItem(LOCAL_DATA_FILTER) ? parseInt(localStorage.getItem(LOCAL_DATA_FILTER)) : 0);      //---------------- un valore numerico per ogni tipo di filtro passato. 0 = recupera i dati con la data solare, 1 = recupera secondo la data di bacco (Default 0 o vuoto)

  const [waitersList, setWaitersList] = useState([]);                                                                                                       //---------------- Lista dei camerieri con i loro dati
  const [waiterName, setWaiterName] = useState("");                                                                                                         //---------------- Nome del cameriere che si sta interrogando in questo momento
  const [waiterCode, setWaiterCode] = useState("");                                                                                                         //---------------- Codice del cameriere passato al WS per recuperare solo i suoi dati
  
  const [showResetFilterChangelog, setShowResetFilterChangelog] = useState(false);                                                                          //---------------- variabile per mostrare il tasto reset dei filtri del changelog
  const [filteredChangelog, setFilteredChangelog] = useState(changeLog);                                                                                    //---------------- changelog che mostra solo i dati filtrati
  
  const [receiptData, setReceiptData] = useState([]);                                                                                                       //---------------- dati degli scontrini emessi per le tabelle
  
  const [openedTable, setOpenedTable] = useState([]);                                                                                                       //---------------- numero di tavoli aperti

  const [dailyDiscount, setDailyDiscount] = useState(0);                                                                                                    //---------------- sconto di oggi

  const [service, setService] = useState(0);                                                                                                                //---------------- servizio per bacco qualora presente
  
  const [shopsListLoading, setShopsListLoading] = useState(true);

  var commonQueryParams         = `type_sale=${transactionType}&shop=${shop}&code_waiter=${waiterCode}&data_filter=${useDataFilter}`;                               //----------------  query params comuni a tutte le chiamate 
  var commonQueryParamsNoWaiter = `type_sale=${transactionType}&shop=${shop}&data_filter=${useDataFilter}`;                               //----------------  query params comuni a tutte le chiamate 

  var refreshVariables = [transactionType, shop, waiterCode, accessToken, useDataFilter];                                                                   //----------------  insieme di variabili per comodità raccolte e usate per aggiornare i vari useEffects


  //settaggio per i prodotti venduti
  const [dailySelledProductTotal, setDailySelledProductTotal] = useState(0);                                                                                //----------------  totale dei prodotti venduti oggi
    

  
  //---------- riferimenti per il tour ----------
  
  const refShowPercentage = useRef(null);
  const refChangeDay = useRef(null); 
  const refDarkMode = useRef(null);
  const refFastStat = useRef(null);   
  const refVersion = useRef(null);
  const refColors = useRef(null);
  const refFilter = useRef(null);
  const refRange = useRef(null);
  const refCsv = useRef(null);

  //---------- riferimenti per il tour ----------

  //---------- gestione della modalità scura ------------------
  const changeDarkMode = (bool) => {
    if (bool) {
      document.documentElement.style.setProperty('--mobile-header-bg', DARK_MOBILE_HEADER);
      document.documentElement.style.setProperty('--primary-card-background', DARK_BG_CONTAINER);
      document.documentElement.style.setProperty('--body-gradient-2', DARK_BG_BODY);
      document.documentElement.style.setProperty('--body-gradient-1', DARK_BG_BODY);
      document.documentElement.style.setProperty('--body-bg', DARK_BG_BODY);
      localStorage.setItem(LOCAL_DARK_MODE, bool);
    } else {
      document.documentElement.style.setProperty('--mobile-header-bg', LIGHT_MOBILE_HEADER);
      document.documentElement.style.setProperty('--primary-card-background', '#fff');
      document.documentElement.style.setProperty('--body-gradient-2', '#eef1f5');
      document.documentElement.style.setProperty('--body-gradient-1', '#fff');
      document.documentElement.style.setProperty('--body-bg', "fff");
      localStorage.removeItem(LOCAL_DARK_MODE);
    }
    setDarkMode(bool);
  }
  //---------- gestione della modalità scura ------------------

  // --------- variabiili globalizzate al progetto con il context ---------
  useEffect(() => {
    
    if (window.location.pathname === "/404") return;
    
    if (isApp === "app" || origin.search("backend.easyappear.it") !== -1 || origin.search("dylogapp.it") !== -1) {
      if (!token) {
        var sessionToken = sessionStorage.getItem(LOCAL_ACCESS_TOKEN);

        setAccessToken(sessionToken);
        if(!sessionToken){
          window.location.href = "/404";
        }
      }else{
          try {
            (async ()=>{
              const res = await fetch(`https://insight.dylogapp.it/api/dy/ins/v1/auth/get_access_token`, {
                method: "POST",
                headers: {
                  "Content-Type": "application/json"
                },
                body:JSON.stringify({"tokenKey":token})
              }).then((t) => t.json());
              if(res.Result === "OK"){
                sessionStorage.setItem(LOCAL_ACCESS_TOKEN, res.Json.access_token)
                sessionStorage.setItem(LOCAL_USER_TYPE, res.Json.entity_type)
                setAccessToken(res.Json.access_token)
                window.location.href = "/dashboard"
              }else{
                window.location.href = "/404";
              }
            })();
          } catch (error) {}
      }

    } else {
      window.open("https://dylogapp.it/login", "_self");
    }

  }, [])
   // --------- variabiili globalizzate al progetto con il context ---------



  //---------- notifiche ------------------

  const openNotificationWaiter = (msg) => {
    api.info({
      message: <><UserOutlined /> {msg} </>,
      description:"Stai vedendo tutti i dati relativi solo a questo cameriere",
      placement: "bottomLeft",
      duration: null,
    });
  };
  const serviceNotification = (msg) => {
    api.info({
      message: <> {msg} </>,
    });
  };
  const closeNotificationWaiter = () => {
    api.destroy();
  }

  //---------- notifiche ------------------

  //---------- filtraggio del changelog ------------------

  const filterChangelog = (type) => {
      setFilteredChangelog(changeLog.map((element) => {
         return { ...element, lines: element.lines.filter((line) => line.type === type) }
      }));
     setShowResetFilterChangelog(true);
   };
  const resetChangelogFilter = () => {
    setFilteredChangelog(changeLog);
    setShowResetFilterChangelog(false);
  };

  //---------- filtraggio del changelog ------------------
   
  
  //---------- recupero la lista degli shops per signa e bacco ed effettuo delle chiamate generiche ------------------

  useEffect(()=>{

    if (localStorage.getItem(LOCAL_DARK_MODE)) {
      changeDarkMode(true);
    }
    
    // chiamate generiche solo per signa | punti vendita | tipi di movimenti
    if(userType === "retail"){
      if(!localStorage.getItem(LOCAL_TRANSACTION_TYPE)){
        setTransactionType("RC+VE");
        localStorage.setItem(LOCAL_TRANSACTION_TYPE, JSON.stringify(["RC","VE"]));
      } 

      (async () => { 
        const json = await ApiCall(`${ENDPOINT_SHOPS}`, "GET", `${accessToken}`, null)
        if(json){
          json.forEach(e=>{
            e["value"] = e["CodNegozio"];
            e["label"] = e["Descrizione"];
            e["key"] = e["CodNegozio"];
          })
          setShopsList(json);
          setShopsListLoading(false);
        }
      })(); 

      (async () => { 
          const json = await ApiCall(`${ENDPOINT_MOVEMENT_TYPE}?date=2000-01-01T00:00:00&end_date=2000-01-01T00:00:00`, "GET", `${accessToken}`, null)
          
          if(json){
            setTransactionList(json);
          }
      })(); 
      
    }

    // chiamate generiche solo per i ristoranti | punti vendita | camerieri
    if (userType === "restaurant") {
      if(!localStorage.getItem(LOCAL_DATA_FILTER)){
        setUseDataFilter(0);
        localStorage.setItem(LOCAL_DATA_FILTER, 0);
      }
      if(!localStorage.getItem(LOCAL_USE_CURRENT_TIME)){
        setUseCurrentTime(false);
        localStorage.setItem(LOCAL_USE_CURRENT_TIME, false);
      } else {
        setUseCurrentTime(JSON.parse(localStorage.getItem(LOCAL_USE_CURRENT_TIME)))
      } 

      (async () => { 
        const json = await ApiCall(`${ENDPOINT_SHOPS}`, "GET", `${accessToken}`, null)
        var tempShopsList = [];

        if(json){
          json.forEach(e => {
            tempShopsList.push(
                { 
                  "value":e.merchant,
                  "label":e.shop_name,
                  "key":e.merchant
                }
              )
          })
        }
        setShopsList(tempShopsList);
        setShopsListLoading(false);
      })(); 

      (async () => {
        const json = await ApiCall(`${ENDPOINT_WAITER_LIST}?shop=${shop}`, "GET", `${accessToken}`, null);
        if(json){
          json.forEach(e=>{
            e["value"] = e["code"];
            e["label"] = e["name"];
            e["key"] = e["code"];
          })
          setWaitersList(json);
        } 
      })()
    }

    // setto il valore salvato nei filtri delle transazioni che voglio vedere
    var settedTransactionType = JSON.parse(localStorage.getItem(LOCAL_TRANSACTION_TYPE));
    var tempTransactionType = "";

    if(settedTransactionType){
      settedTransactionType.forEach((e)=>{
        tempTransactionType += e + "+"
      })
    }

    setTransactionType(tempTransactionType.substring(0, (tempTransactionType.length - 1))); //tolgo l'ultimp + davanti alla stringa

  }, [accessToken, userType])
    
  //---------- recupero la lista degli shops per signa e bacco ed effettuo delle chiamate generiche ------------------

  useEffect(() => {
    setIsRestaurant(sessionStorage.getItem(LOCAL_USER_TYPE) === "restaurant" ? true : false);
    setUserType(sessionStorage.getItem(LOCAL_USER_TYPE));
  }, [accessToken]); //----- controllo aggiuntivo con refresh access token e tipo di utente, quando si fanno continui cambi di urls o tokens


  return (
  

    <ConfigProvider
      theme={
      {
          algorithm: darkMode ? theme.darkAlgorithm : theme.compactAlgorithm,
          token:{
            colorPrimary: "#008833",
            colorBgContainer: darkMode ? DARK_BG_CONTAINER : "#fff"
          }
        }
      }
    >
       <Context.Provider
            value={{
                
              closeNotificationWaiter,
              openNotificationWaiter,
              
              changeDarkMode,
              setDarkMode,
              darkMode,

              showResetFilterChangelog,
              resetChangelogFilter,
              filteredChangelog,
              filterChangelog,
                  
              setService,
              service,
                
              setShopsList,
              shopsList,
  
              shopsListLoading,
              setShopName,
              shopName,
              
              setChangeFilter,
              changeFilter,
              
              setUseCurrentTime,
              useCurrentTime,
  
              setUseDataFilter,
              useDataFilter,
              
              setAccessToken,
              accessToken,
              
              setWaiterCode,
              waiterCode,
  
              setWaiterName,
              waiterName,
  
              setDailyDiscount,
              dailyDiscount,
  
              setWaitersList,
              waitersList,
              
              setShop,
              shop,
                  
              setDailySelledProductTotal,
              dailySelledProductTotal,
            
              setOpenedTable,
              openedTable,
                  
              refShowPercentage,
              refChangeDay,
              refFastStat,
              refDarkMode,
              refVersion,
              refColors,
              refFilter,
              refRange,
              refCsv,
          
              serviceNotification,
                  
              commonQueryParamsNoWaiter,
              commonQueryParams,
              refreshVariables,    
          
              setTransactionList,
              transactionList,
              
              setTransactionType,
              transactionType,
                  
              setReceiptData,
              receiptData,
              
              setIsRestaurant,
              isRestaurant,
              userType,
                  
              setOpen,
              open,
            }}
       >
           {contextHolder}
           <BrowserRouter>
             <Routes>
               
               {/* routes pubbliche */}
               <Route path='/waiter/:id' element={<SingleWaiterData />}/>
               <Route path='/opened-tables' element={<OpenedTables />}/>
               <Route path='/operators' element={<Operators />}/>
               <Route path='/compare' element={<Compare />}/>
               <Route path='/dashboard' element={<App />}/>
               <Route path='/404' element={<Page404 />}/>
               <Route path='/*' element={<App />}/>
               <Route path='/' element={<App />}/>
       
               {/* routes private */}
               <Route element={<PrivateRoutes/>}>
               </Route>
       
             </Routes>
           </BrowserRouter>
       </Context.Provider>
  </ConfigProvider>
  )
}