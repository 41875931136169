import React, { useContext, useEffect, useState } from 'react'
import dayjs from 'dayjs';

import { Col, Row, Switch, Divider } from 'antd';
import { EuroOutlined } from '@ant-design/icons';

import { ENDPOINT_PERIOD_SALES, ENDPOINT_TIME_SLOTS, LOCAL_EARN_COLOR } from '../../helpers/Globals';
import DownloadCsvButton from '../DownloadCsvButton';
import DiPaletteSelector from '../DiPaletteSelector';
import { Context } from '../../helpers/Context';
import BarGrouped from '../charts/BarGrouped';
import DiRangePicker from '../DiRangePicker';
import ChartSkeleton from '../ChartSkeleton';
import { ApiCall } from '../../Backoffice';
import BarChart from '../charts/BarChart';
import DiTooltip from '../DiTooltip';
import NoData from '../NoData';

export default function DisplayDailyEarn() {
   

   const [earnColor, setEarnColor] = useState(localStorage.getItem(LOCAL_EARN_COLOR) ? localStorage.getItem(LOCAL_EARN_COLOR) : "pastel2");
   const [sdEarn, setSdEarn] = useState(dayjs().format('YYYY-MM-DDT00:00:00'));
   const [edEarn, setEdEarn] = useState(dayjs().format('YYYY-MM-DDT23:59:59'));
   const [showTotalEarn, setShowTotalEarn] = useState(false); //switcher per mostrare il totale guadagnato o il guadagno per singoli orari
   const [totalDailyEarn, setTotalDailyEarn] = useState([]); //totale guadagnato nei giorni scelti
   const [resetEarnDate, setResetEarnDate] = useState(0);
   const [loadingEarn, setLoadingEarn] = useState(false);
   const [totalEarn, setTotalEarn] = useState(0); //non usata 
   const [timeSlots, setTimeSlots] = useState([]); //dati medi dei singoli orari per giorno del venduto
   const [earn, setEarn] = useState([]); //json del totale guadagnato nei singoli slot orari dei giorni scelti
   
  // ---- variabili dal context -----
  const {
      refreshVariables,
      commonQueryParams,
      setDailyDiscount,
      accessToken,
   } = useContext(Context);
   // ---- variabili dal context -----

   
      // settare la data giornaliera dei guadagni
      useEffect(() => {

        var startDate = dayjs().format('YYYY-MM-DDT00:00:00');
        var endDate = dayjs().format('YYYY-MM-DDT23:59:59');

        setSdEarn(startDate)
        setEdEarn(endDate)

      },[resetEarnDate])

      //chiamata a tempo variabile per ricevere i dati
      useEffect(()=>{

        setLoadingEarn(true);
        
        (async () => {
          const json = await ApiCall(`${ENDPOINT_PERIOD_SALES}?date=${sdEarn}&end_date=${edEarn}&${commonQueryParams}`, "GET", `${accessToken}`, null)
          
          
          if (json) {
            
            var sales = json.sales
            var totalEarn = 0; 
            var tempData = [];
            var dailyDiscount = 0;
            for (const i in sales) {
                //recupero dal period sales il giorno, l'ora ed il totale per ora
                var day = sales[i]["date_bill"].split("T")[0];
                var time = sales[i]["date_bill"].split("T")[1].split(":")[0] + ":00";
                var total = 0;

                dailyDiscount += parseFloat(sales[i]["discount"]);

                //sommo i totali delle singole battute 
                for (let n in sales[i]["lines"]) {
                 total += sales[i]["lines"][n]["amount"];
                }
                //inserisco nell'array temporaneo tutti gli ogetti nuovi (giorno ed orario con totale orario)
                if(tempData.find(e => e["Giorno"] === day)){
                  //se in tempData si trova già il giorno, entra qui e verifica se non ci sia l'orario
                  //se trova l'orario vi somma il totale per quell'ora (unificando le battute per ora)
                  if(tempData[tempData.findIndex(e => e["Giorno"] === day)][time] !== undefined){
                    tempData[tempData.findIndex(e => e["Giorno"] === day)][time] = tempData[tempData.findIndex(e => e["Giorno"] === day)][time] + parseFloat(total.toFixed(2));
                    tempData[tempData.findIndex(e => e["Giorno"] === day)][time] = parseFloat(tempData[tempData.findIndex(e => e["Giorno"] === day)][time].toFixed(2));
                    totalEarn = totalEarn + parseFloat(total.toFixed(2));
                  }else{
                    //altrimenti inserisce nell'oggetto giorno, un nuovo orario con il rispettivo totale
                    tempData[tempData.findIndex(e => e["Giorno"] === day)][time] = parseFloat(total.toFixed(2));
                    totalEarn = parseFloat(totalEarn.toFixed(2)) + parseFloat(total.toFixed(2));
                  }
                }else{
                  //se non c'è il giorno lo crea la prima volta
                  var dailyEarn = {
                    "Giorno":day,
                    [time]:parseFloat(total.toFixed(2)),
                  }
                  tempData.push(dailyEarn)
                  totalEarn += parseFloat(total.toFixed(2));
                }
            }

            var totalEarning = []; //json temporaneo per creare i vari oggetti (giorni) con le somme dei guadagni orari

            //calcolo i totali dei singoli giorni
            tempData.forEach(e=>{
              var total = 0;
              var day = "";
              for (const i in e) {
                if(i !== "Giorno"){
                  total += e[i]; 
                }else day = e[i];
              }
              totalEarning.push({
                "Giorno":day,
                "Totale":total.toFixed(2)
              });
            })
            setDailyDiscount(dailyDiscount);
            setTotalDailyEarn(totalEarning);
            setEarn(tempData);
            setTotalEarn(totalEarn);
            setLoadingEarn(false);
          }
          //chiamata per ricevere gli slot di tempo con i guadagni con le stesse date degli incassi orari (${ENDPOINT_TIME_SLOTS})

          (async()=>{
            const json = await ApiCall(`${ENDPOINT_TIME_SLOTS}?date=${sdEarn}&end_date=${edEarn}&${commonQueryParams}`, "GET", `${accessToken}`, null)
            if(json){
          
              var tempData = [];

              for (const i in json) {
                tempData.push({
                  "orario":i.split(":")[0],
                  "incasso":json[i].toFixed(2)
                })
              };
            }
            tempData.sort((a,b) => a.orario - b.orario);
            setTimeSlots(tempData);
          })()
          
        })(); 

      }, [sdEarn, edEarn, ...refreshVariables]);
   

      // ---------------------  gestione del range date per i guadagni --------------------- 
      const earnRangeDate = (dates, dateStrings) => {

        if (dates) {

          var newStartDate = dateStrings[0].replaceAll("/", "-").replaceAll(/\s/g,'T');
          var newEndDate = dateStrings[1].replaceAll("/", "-").replaceAll(/\s/g,'T');
          setSdEarn(newStartDate);
          setEdEarn(newEndDate);

        } else {
          setResetEarnDate(resetEarnDate + 1);
        }
      };

   
   
  return (
    <div className="di-card h-100">
      <Row>
         <Col span={24}>
         <div className='flex column align-center space-between gap-10'>
            <div className='flex gap-10 color-grey fs-15'>
               <div className='flex align-center gap-5'><EuroOutlined className="color-primary fs-20"/> Incasso giornaliero suddiviso per orari</div>
               <DiTooltip title="Intervallo temporale del guadagno per base giornaliera/oraria (default giornaliero)"/>
            </div>
            <div>Totale: <b>{totalEarn.toFixed(2)}€</b></div>
            <Row gutter={[20,20]} justify={"space-between"} align={"top"} className='w-100'>
               <Col xs={{span:24}} sm={{span:10}}>
               <DiRangePicker onChange={earnRangeDate}/>
               </Col>
               <Col xs={{span:24}} sm={{span:14}}>
               <div className='flex column gap-10 align-end'>
               {/* selettore colore e download csv */}
               <div className='flex gap-10'>
                  <DiPaletteSelector
                    defaultValue={earnColor}
                    onChange={
                    (value)=>{
                        setEarnColor(value);
                        localStorage.setItem(LOCAL_EARN_COLOR,value)
                    }
                    }
                  />
               {showTotalEarn ?
                     <DownloadCsvButton
                        key={"csvTotalDailyEarn"}
                        dataToDownload={totalDailyEarn}
                        filename={"totale-guadagno-giornaliero"}
                     />
                     :
                     <DownloadCsvButton
                        key={"csvEarn"}
                        dataToDownload={earn}
                        filename={ "guadagno-giornaliero-per-orari"}
                     />
                  }
               </div>
               {/* switcher totale orario / giornaliero */}
               <div className='flex gap-5 align-center flex-wrap mb-20 mt-10'>
                  <div>Mostra per:</div>
                  <Switch
                     checkedChildren="Giornaliero" 
                     unCheckedChildren="Orario" 
                     onChange={()=>setShowTotalEarn(showTotalEarn ? false : true)}
                  />
               </div>

               </div>
               </Col>
            </Row>
         </div>
         </Col>
      </Row>
      {/* range di date per prodotti venduti e switcher del grafico*/}
      { 
         !loadingEarn && earn.length !== 0 ? 
            <>
               <BarGrouped
                data={showTotalEarn ? totalDailyEarn : earn} 
                colors={earnColor} 
                xIndex="Giorno" 
                suffix={"€"}
                layout={window.innerWidth > 1000 ? "vertical" : "horizontal"} 
                margin={window.innerWidth < 1000 ?  { top: 40, right: 10, bottom: 20, left: 80 } : { top: 50, right: 50, bottom: 50, left: 60 }}
               />   
               <Divider>Miglior orario medio di vendita</Divider>
               <BarChart
                layout={window.innerWidth > 1100 ? "vertical" : "horizontal"} 
                data={timeSlots} 
                colors={earnColor} 
                xIndex="orario" 
                yIndex="incasso"
                suffix={"€"}
               />
            </> :
         !loadingEarn && earn.length  ===  0 ? <NoData/>
         :
         loadingEarn ? <ChartSkeleton/> 
         : 
         ""
      }
   </div>
  )
}
