import React from 'react'

import { DatePicker } from 'antd';
import { dateHourFormat, disable12MonthsDate, rangePresets } from '../helpers/DateUtils';

const { RangePicker } = DatePicker;


export default function DiRangePicker({onChange}) {
  return (
     <RangePicker
         placeholder={["Inizio","Fine"]}
         presets={rangePresets}
         className='mobile-w-100'
         showTime
         disabledDate={disable12MonthsDate}
         format={dateHourFormat}
         onChange={onChange}
         variant="filled"
      />
  )
}
