import React, { useContext, useEffect, useState } from 'react';

import { ArrowLeftOutlined } from '@ant-design/icons';
import * as Unicons from '@iconscout/react-unicons';

import { useNavigate } from 'react-router';
import { Button, Col, Row, Typography} from 'antd';
import dayjs from 'dayjs';


import { ENDPOINT_PERIOD_SALES } from '../../helpers/Globals';
import { ApiCall } from '../../Backoffice';
import TableCard from '../TableCard';

const { Paragraph } = Typography;

function formatEuro(number) {
  return Number(number.toFixed(2)).toLocaleString("es-ES", {minimumFractionDigits: 2});
}

function OpenedTables(props) {

  const [publicCodeFilter,] = useState(sessionStorage.getItem("__di_public_code_filter") ? sessionStorage.getItem("__di_public_code_filter") : "")
  const [openedTableCount, setOpenedTableCount] = useState(0);
  const [openedTableEarn, setOpenedTableEarn] = useState(0);
  const [openedTable, setOpenedTable] = useState([]);
  const [coperti, setCoperti] = useState(0);

  const accessToken = sessionStorage.getItem("__access_token");

  const navigate = useNavigate();

  //chiamata per ricevere i dati dei tavoli aperti
  useEffect(()=>{
    var startDate = dayjs().format('YYYY-MM-DDT00:00:00');
    var endDate = dayjs().format('YYYY-MM-DDT23:59:59');

    (async () => {

      const json = await ApiCall(`${ENDPOINT_PERIOD_SALES}?date=${startDate}&end_date=${endDate}&shop=${publicCodeFilter}&opened=1`, "GET", `${accessToken}`, null)
      if (json) {
        
        var openedTable = json.sales;
        var totalOpenedTableEarn = 0;
        var coperti = 0;

        for (const i in openedTable) {
         
          //sommo i totali delle singole battute 
          for (let n in openedTable[i]["lines"]) {
            
            var line = openedTable[i]["lines"][n];

            totalOpenedTableEarn = totalOpenedTableEarn + line["amount"];
            if (line["description"] === "COPERTO") {
              coperti += line["quantity"]
            }
          }
        }
        setOpenedTableEarn(totalOpenedTableEarn);
        setOpenedTableCount(openedTable.length);
        setOpenedTable(openedTable);
        setCoperti(coperti);
      }
    })();

  },[]);

  return (
      <>
        <div className='flex align-center gap-5 mb-20 pt- p-10 mobile-show'>
          <Unicons.UilClipboardNotes className="color-primary"/>
          <span className='fs-18'>Tavoli Aperti</span>
        </div>
        <Row gutter={[15,15]}>
          <Col span={24}>
            <div className='di-card flex space-between flex-wrap mt-10'>
              <div className='flex gap-10 align-center flex-wrap'>
                <Button
                  type='primary'
                  icon={<ArrowLeftOutlined />}
                  onClick={
                    () => {
                      sessionStorage.removeItem("__di_public_code_filter");
                      navigate(-1);
                    }
                  }
                >
                  Dashboard
                </Button>
                <span className='fs-18 flex-wrap'>
                  <Paragraph className='mb-0 flex flex-wrap gap-5 fs-18'>
                    {openedTableCount} tavoli aperti per un totale di
                    <span className='color-primary fw-5'>
                      {formatEuro(openedTableEarn)}€
                    </span>
                    e
                    <span className='color-primary fw-5'>
                      {coperti} 
                    </span>
                    Coperti
                  </Paragraph>
                </span>
              </div>    
              <div className='flex align-center gap-5 py-10 mobile-hide'>
                <Unicons.UilClipboardNotes className="color-primary"/>
                <Paragraph className='fs-18 mb-0'>Tavoli Aperti</Paragraph>
              </div>
            </div>
          </Col>
        </Row>
        <Row className='mt-20' gutter={[15,15]}>
          {openedTable.map((e, index)=>{
            return(
                <Col 
                  className='p-0 animate opacity-0 fadeInUp' 
                  style={{animationDelay:index + "50ms"}}
                  key={index}
                  xs={24}
                  sm={24}
                  md={12}
                  lg={8}
                  xl={6}
                  xxl={4}
                >
                  <TableCard table_data={e}/>
                </Col>
              )
            })
          }
        </Row>
      </>
  );
}

export default OpenedTables;