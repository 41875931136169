import React, { useContext, useEffect, useState } from 'react'

import { useNavigate } from 'react-router';

import { Button, Col, Divider, Row } from 'antd'
import * as Unicons from '@iconscout/react-unicons';

import { Context } from '../../../helpers/Context';

import dayjs from 'dayjs';

import { ENDPOINT_PERIOD_SALES } from '../../../helpers/Globals';
import { ApiCall } from '../../../Backoffice';


export default function DisplayFastDailyOpenedTable() {
   
   const [openedTableCount, setOpenedTableCount] = useState(0);   

   const navigate = useNavigate();
   

   // ---- variabili dal context -----
   const {
      commonQueryParams,
      refreshVariables,
      accessToken,
   } = useContext(Context);
   // ---- variabili dal context -----

   // --------------------- chiamata per ricevere i dati dei tavoli aperti --------------------- 
   useEffect(() => {
      
      var startDate = dayjs().format('YYYY-MM-DDT00:00:00');
      var endDate = dayjs().format('YYYY-MM-DDT23:59:59');

      (async () => {
         const json = await ApiCall(`${ENDPOINT_PERIOD_SALES}?date=${startDate}&end_date=${endDate}&opened=1&${commonQueryParams}`, "GET", `${accessToken}`, null)
         if (json) {
         setOpenedTableCount(json.sales.length);
         }
      })();

   }, [...refreshVariables]);
   
  return (
      <div className="di-card h-100">
         <Row gutter={[15, 15]} justify="center" align="middle">
       
         <Col align="center">
            <div>
               <Unicons.UilClipboardNotes className="color-primary"/>
            </div>
         </Col>
           
         <Col align="center">
            <div className='fs-18 fw-5'>Tavoli Aperti</div>
         </Col>
      
         </Row>
         <Divider className='mt-10 mb-10'/>
      
         <Row gutter={[15, 15]} justify="center" align="middle">
         <Col xs={24} xl={4} align="center" className='fs-30'>
               {openedTableCount}
         </Col>
         </Row>
      
         <Divider className='mt-10 mb-10'/>
         <Row gutter={[15, 15]} justify="space-between" align="middle">
         <Button onClick={()=>navigate("/opened-tables")} type="primary" className="mt-20" block>Vedi tavoli Aperti</Button>
         </Row>
      </div>
  )
}
