const colorsPalette = [
  {
    value: 'category10',
    label: 'Category 10',
  },
  {
    value: 'accent',
    label: 'Accenti',
  },
  {
    value: 'dark2',
    label: 'Scura',
  },
  {
    value: 'paired',
    label: 'Pari',
  },
  {
    value: 'pastel1',
    label: 'Pastello 1',
  },
  {
    value: 'pastel2',
    label: 'Pastello 2',
  },
  {
    value: 'set1',
    label: 'Set 1',
  },
  {
    value: 'set2',
    label: 'Set 2',
  },
  {
    value: 'set3',
    label: 'Set 3',
  },
  {
    value: 'brown_blueGreen',
    label: 'Marrone a VerdeAcqua',
  },
  {
    value: 'purpleRed_green',
    label: 'Viola a Verde',
  },
  {
    value: 'pink_yellowGreen',
    label: 'Rosa a Lime',
  },
  {
    value: 'purple_orange',
    label: 'Viola ad Arancione',
  },
  {
    value: 'red_blue',
    label: 'Rosso a Blu',
  },
  {
    value: 'red_grey',
    label: 'Rosso a Grigio',
  },
  {
    value: 'red_yellow_blue',
    label: 'Rosso Giallo Blu',
  },
  {
    value: 'red_yellow_green',
    label: 'Rosso Giallo Verde',
  },
  {
    value: 'spectral',
    label: 'Spettro',
  },
  {
    value: 'blues',
    label: 'Blu',
  },
  {
    value: 'greens',
    label: 'Verdi',
  },
  {
    value: 'greys',
    label: 'Grigi',
  },
  {
    value: 'oranges',
    label: 'Arancioni',
  },
  {
    value: 'purples',
    label: 'Viola',
  },
  {
    value: 'reds',
    label: 'Rossi',
  },
  {
    value: 'blue_green',
    label: 'Blu/Verde',
  },
  {
    value: 'blue_purple',
    label: 'Blu/Viola',
  },
  {
    value: 'green_blue',
    label: 'Verde/Blu',
  },
  {
    value: 'orange_red',
    label: 'Arancione/Rosso',
  },
  {
    value: 'purple_blue_green',
    label: 'VIola/Blu/Verde',
  },
  {
    value: 'purple_blue',
    label: 'Viola/Blu',
  },
  {
    value: 'purple_red',
    label: 'Viola/Rosso',
  },
  {
    value: 'red_purple',
    label: 'Rosso/Viola',
  },
  {
    value: 'yellow_green_blue',
    label: 'Giallo/Verde/Blu',
  },
  {
    value: 'yellow_green',
    label: 'Giallo/Verde',
  },
  {
    value: 'yellow_orange_brown',
    label: 'Giallo/Arancione/Marrone',
  },
  {
    value: 'yellow_orange_red',
    label: 'Giallo/Arancione/rosso',
  },
]

export {colorsPalette};