import React from 'react'

import { Select } from 'antd';

import { colorsPalette } from '../helpers/ColorsPalette';

export default function DiPaletteSelector({defaultValue, onChange}) {
  return (
       <Select
         showSearch
         placeholder="Seleziona Colore"
         optionFilterProp="children"
         className='mobile-w-100'
         defaultValue={defaultValue}
         onChange={onChange}
         filterOption={(input, option) =>
            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
         }
        options={colorsPalette}
        variant='filled'
      />
  )
}
