import React, { useContext, useEffect, useState } from 'react'

import { Col, Row, Spin } from 'antd';

import dayjs from 'dayjs';

import { ENDPOINT_SOLD_ITEMS, LOCAL_TOP_10_CATEGORIES_COLOR } from '../../helpers/Globals';
import DownloadCsvButton from '../DownloadCsvButton';
import DiPaletteSelector from '../DiPaletteSelector';
import { Context } from '../../helpers/Context';
import DiRangePicker from '../DiRangePicker';
import { ApiCall } from '../../Backoffice';
import PieChart from '../charts/PieChart';
import DiTooltip from '../DiTooltip';
import NoData from '../NoData';
import { StarFilled } from '@ant-design/icons';

export default function DisplayTop10Categories() {
   

   const [top10CategoriesColor, setTop10CategoriesColor] = useState(localStorage.getItem(LOCAL_TOP_10_CATEGORIES_COLOR) ? localStorage.getItem(LOCAL_TOP_10_CATEGORIES_COLOR) : "nivo");
   const [sdTop10Categories, setSdTop10Categories] = useState(dayjs().format('YYYY-MM-DDT00:00:00'));
   const [edTop10Categories, setEdTop10Categories] = useState(dayjs().format('YYYY-MM-DDT23:59:59'));
   const [resetTop10CategoriesDate, setResetTop10CategoriesDate] = useState(0);
   const [loadTop10Categories, setLoadTop10Categories] = useState(true);
   const [top10Categories, setTop10Categories] = useState([]);
   

  // ---- variabili dal context -----
  const {
      refreshVariables,
      commonQueryParams,
      accessToken,
   } = useContext(Context);
  // ---- variabili dal context -----

   

// --------------------- area per gestire le top 10 categorie --------------------- 
        useEffect(()=>{

          var startDate = dayjs().format('YYYY-MM-DDT00:00:00');
          var endDate = dayjs().format('YYYY-MM-DDT23:59:59');

          setSdTop10Categories(startDate)
          setEdTop10Categories(endDate)

        },[resetTop10CategoriesDate])

        //chiamata per ricevere i dati venduto prodotti per periodo
        useEffect(()=>{
          setLoadTop10Categories(true);

          (async () => { 
            let json = await ApiCall(`${ENDPOINT_SOLD_ITEMS}?date=${sdTop10Categories}&end_date=${edTop10Categories}&${commonQueryParams}`, "GET", `${accessToken}`, null)
            if(json){

              var categories = [];
              for (const i in json) {
                if(json[i].category){
                  if (json[i].category === "SERVIZIO") continue;
                  const index = categories.findIndex(x => x.category === json[i].category);

                  if(index > -1){
                    categories[index].quantity += json[i].quantity;
                  }else{
                    categories.push({
                      "category":json[i].category,
                      "quantity":json[i].quantity
                    })
                  }
                }
              }
              for (let i = 0; i < categories.length; i++) {
                categories[i]["category"] = categories[i]["category"].replaceAll("--", "");
                 //modifica delle chiavi prodotto in etichette per i grafici
                  categories[i]["id"] = categories[i]["category"];
                  categories[i]["label"] = categories[i]["category"];
                  categories[i]["value"] = categories[i]["quantity"];
              }
              var top10Categories = categories.slice(0,10);
              
              setTop10Categories(top10Categories);
              setLoadTop10Categories(false);

            }
          })(); 

        },[sdTop10Categories, edTop10Categories, ...refreshVariables]);

        // gestione del range date per il totale venduto prodotti
        const top10CategoriesRange = (dates, dateStrings) => {

          setLoadTop10Categories(true);
          if (dates) {

            var newStartDate = dateStrings[0].replaceAll("/", "-").replaceAll(/\s/g,'T');
            var newEndDate = dateStrings[1].replaceAll("/", "-").replaceAll(/\s/g,'T');
            setSdTop10Categories(newStartDate);
            setEdTop10Categories(newEndDate);
            setLoadTop10Categories(false);
          } else {
            setResetTop10CategoriesDate(1);
            setLoadTop10Categories(false);
          }
        };
   
  return (
     <div className="di-card h-100">
      <Row>
         <Col span={24}>
         <div className='flex column align-center space-between gap-10'>
            <div className='flex gap-10 color-grey fs-15'>
               <div className='flex align-center gap-5'><StarFilled className='color-yellow'/> Top 10 Categorie</div>
              <DiTooltip text={"Le top 10 categorie di prodotti più venduti per l'intervallo di tempo stabilito"}/>
            </div>
               <div className='flex align-center gap-10 space-between w-100 flex-wrap'>
               <DiRangePicker onChange={top10CategoriesRange}/>
               <div className='flex gap-10'>
                <DiPaletteSelector
                    defaultValue={top10CategoriesColor}
                    onChange={
                      (value)=>{
                        setTop10CategoriesColor(value);
                        localStorage.setItem(LOCAL_TOP_10_CATEGORIES_COLOR,value)
                      }
                      }
                  />
                  {top10Categories.length > 0 && <DownloadCsvButton dataToDownload={top10Categories} filename={"top-10-categorie-vendita"}/>}
               </div>
               </div>
         </div>
         </Col>
      </Row>
      <Row>
         <Col span={24}>
         {
            top10Categories.length === 0 ? <NoData/>
            :
            loadTop10Categories ? <div className="w-100 flex align-center justify-center pie-chart"><Spin /></div>
            :                 
            <div className="pie-chart">
               <PieChart data={top10Categories} colors={top10CategoriesColor}/>
            </div>
         }
         </Col>
      </Row>
   </div>
  )
}
