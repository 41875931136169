import React from 'react';

import { Col, Row, Skeleton } from 'antd';

function TableSkeleton(props) {
  return (
    <div className='table-skeleton flex column gap-20'>
      <div className='flex column gap-10'>
        <Skeleton.Input active size={30} className='w-100'/>
        <Skeleton.Input active size={30} className='w-100'/>
        <Skeleton.Input active size={30} className='w-100'/>
        <Skeleton.Input active size={30} className='w-100'/>
        <Skeleton.Input active size={30} className='w-100'/>
      </div>
    </div>  
);
}

export default TableSkeleton;