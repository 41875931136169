import { Anchor } from 'antd'
import React, { useContext } from 'react'
import { Context } from '../helpers/Context'

export default function DiScrollspy() {
  
  const {isRestaurant} = useContext(Context)

  return (
        <Anchor
          className='p-10 di-scrollspy'
          items={[
            {
              key: 'fastStats',
              href: '#fastStats',
              title: 'Statistiche Rapide',
            },
            {
              key: 'top10',
              href: '#top10',
              title: 'Top 10',
            },
            {
              key: 'paymentMethod',
              href: '#paymentMethod',
              title: 'Metodi di Pagamento',
            },
            {
              key: 'selledProduct',
              href: '#selledProduct',
              title: 'Prodotti Venduti',
            },
            {
              key: 'earn',
              href: '#earn',
              title: 'Incassato',
            },
            isRestaurant ? //scrollspy solo per i ristoranti
            {
              key: 'tables',
              href: '#tables',
              title: 'Tabelle',
            }:{},
          ]}
        />
  )
}
