import React, { useContext, useEffect, useState } from 'react';

import { Typography, Modal, Badge } from 'antd';

import { LOCAL_LAST_VERSION } from '../helpers/Globals';
import ChangeLogTitle from './ChangeLogTitle';
import { Context } from '../helpers/Context';
import ShowMore from './ShowMore';


const packageJson = require("../../package.json");
const currentVersion = packageJson.version;


export default function Version() {
   const [open, setOpen] = useState(false);
   const [show, setShow] = useState(false);

   const { resetChangelogFilter, filteredChangelog } = useContext(Context);

   useEffect(() => {
      setShow(localStorage.getItem(LOCAL_LAST_VERSION) === currentVersion ? false : true)
   }, [])

   const onClick = () => {
      setOpen(true);
      localStorage.setItem(LOCAL_LAST_VERSION, currentVersion); 
   };

  return (
    <>
      <div className='version-label text-center mt-10' >
         <Badge key={"versionLabel"} dot={show} className={show && "pulse"}>
              <a href="#" onClick={onClick}>Novità della {currentVersion}</a>
         </Badge>
      </div>
           
      <Modal
         centered
         open={open}
         footer={false}
         onOk={() => setOpen(false)}
         onCancel={() => {
            resetChangelogFilter();
            setOpen(false)
         }}
         width={1000}
         className='changelog-modal' 
         destroyOnClose
      >
         <ChangeLogTitle />
           
         <ShowMore>
            {filteredChangelog && filteredChangelog.map((e, index) => {
               return(
                     <div key={`v-${index}`}>
                        <Typography.Title level={5}>{e.version}</Typography.Title>
                        {e.lines.map((l, index) => {
                           return (
                           <div key={`l-${index}`}>
                                 <Badge
                                    key={`b-${index}`}
                                    text={l.text}
                                    status={
                                       !l.type ? "default" :
                                       l.type === "fix" ? "error" :
                                       l.type === "add" ? "success" :
                                       l.type === "mod" ? "warning" : "default"
                                    }
                                 />
                                 <br></br>
                           </div>
                           )
                        })   
                        }
                     </div>
                  )
               })
              }
         </ShowMore>
      </Modal>
    </>
  )
}
