import React, { useContext, useState } from 'react'

import { Checkbox, Col, Divider, Drawer, FloatButton, Row, Select} from 'antd';
import { FilterFilled} from '@ant-design/icons';

import { LOCAL_DASHBOARD_FILTERS, LOCAL_DATA_FILTER, LOCAL_TRANSACTION_TYPE, LOCAL_USE_CURRENT_TIME } from '../helpers/Globals';
import { Context } from '../helpers/Context';
import DiTooltip from './DiTooltip';

export default function FiltersDrawer() {

   const [open, setOpen] = useState(false);
   
   const {
      closeNotificationWaiter,
      openNotificationWaiter,
      setTransactionType,
      setUseCurrentTime,
      setUseDataFilter,
      setChangeFilter,
      transactionList,
      useCurrentTime,
      setWaiterCode,
      setWaiterName,
      useDataFilter,
      changeFilter,
      isRestaurant,
      waitersList,
      waiterCode,
      refFilter,
      userType,
   } = useContext(Context);
   

  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };

   
  //gestione del cambio statistiche rapide 
  const changeFastStats = (value) => {
    const useCurrentTime = value.target.checked;
    localStorage.setItem(LOCAL_USE_CURRENT_TIME, useCurrentTime)
    setUseCurrentTime(useCurrentTime)
    setOpen(false);
    setChangeFilter(changeFilter + 1);
  };
   
  //gestione delle spunte filtro
  const setFiltersList = (checkedValues) => {
    localStorage.setItem(LOCAL_DASHBOARD_FILTERS, JSON.stringify(checkedValues))
    setChangeFilter(changeFilter + 1);
  };

  //gestione delle spunte tipo di transazione
  const changeTransaction = (checkedValues) => {
    localStorage.setItem(LOCAL_TRANSACTION_TYPE, JSON.stringify(checkedValues))
    
    var tempTransactionType = "";

    checkedValues.forEach((e)=>{
      tempTransactionType += e + "+"
    })

    setTransactionType(tempTransactionType.substring(0, (tempTransactionType.length - 1))); //tolgo l'ultimo + davanti alla stringa

    setChangeFilter(changeFilter + 1);
  };
   
  //gestione della spunta data filtro
  const changeDataFilter = (value) => {
    const dataFilter = value.target.checked ? 1 : 0;
    localStorage.setItem(LOCAL_DATA_FILTER, dataFilter)
    setUseDataFilter(dataFilter)
    setChangeFilter(changeFilter + 1);
    setOpen(false);
  };
  
  return (
     <>
        <FloatButton ref={refFilter} icon={<FilterFilled />} onClick={showDrawer} className="bottom-20" type="primary" />
        <Drawer
          title="Scegli quale dato vedere"
          onClose={onClose}
          open={open}
          destroyOnClose
          styles={{
            paddingBottom: 80,
          }}
        >
           <Checkbox.Group
              style={{
                width: '100%',
              }}
              onChange={setFiltersList}
              defaultValue={JSON.parse(localStorage.getItem(LOCAL_DASHBOARD_FILTERS))}
            >
              <Row>
                <Col span={24}>
                  <Checkbox value="selledProduct" name="selledProduct">Prodotti venduti</Checkbox>
                  <DiTooltip text="Grafico a barre con ogni prodotto venduto, la sua quantità ed incasso totale per prodotto"/>
                </Col>
                <Col span={24}>
                  <Checkbox value="earn" name="earn">Incasso giornaliero su base oraria</Checkbox>
                  <DiTooltip text="Grafico a barre con tutti con ogni prodotto venduto, la sua quantità totale ed il guadagno per prodotto"/>
                </Col>
                <Col span={24}>
                  <Checkbox value="top10Products" name="top10Products">Top 10 Prodotti</Checkbox>
                  <DiTooltip text="Grafico che mostra i 10 prodotti più venduti"/>
                </Col>
                <Col span={24}>
                  <Checkbox value="top10Categories" name="top10Categories">Top 10 Categorie</Checkbox>
                  <DiTooltip text="Grafico che mostra le 10 categorie di prodotti più vendute"/>
                    
                </Col>
                <Col span={24}>
                  <Checkbox value="paymentMethod" name="paymentMethod">Metodi di Pagamento</Checkbox>
                  <DiTooltip text="Grafico che mostra i totali incassati suddivisi per metodi di pagamento"/>
                </Col>

                {/* ----------------------- gruppo di dati condizionale, visible solo se si è ristoratori ----------------------- */}
                { isRestaurant &&
                  <>
                    <Col span={24}>
                      <Checkbox value="openedTable" name="openedTable">Tavoli aperti</Checkbox>
                      <DiTooltip text="Lista delle postazioni aperta con il dettaglio dei prodotti"/>
                    </Col>
              
                    <Col span={24}>
                      <Checkbox value="receiptData" name="receiptData">Scontrini emessi</Checkbox>
                      <DiTooltip text="Lista degli scontrini emessi con dettaglio prodotti e camerieri"/>
                    </Col>
              
                    <Col span={24}>
                      <Checkbox value="selledCategories" name="selledCategories">Venduto per Categorie</Checkbox>
                      <DiTooltip text="Lista dei prodotti venduti raggruppati per categorie"/>
                    </Col>
              
                    <Col span={24}>
                      <Checkbox value="waitersProduction" name="waitersProduction">Produzione Camerieri</Checkbox>
                      <DiTooltip text="Dati sull'incassato e venduto dei camerieri"/>
                    </Col>
              
                  </>
                }
                {/* ----------------------- gruppo di dati condizionale, visible solo se si è ristoratori ----------------------- */}

                {/* ----------------------- gruppo di dati condizionale, visible solo se si è retailer ----------------------- */}
                { !isRestaurant &&
                  <>
                    <Col span={24}>
                      <Checkbox value="brands" name="brands">Marchi</Checkbox>
                      <DiTooltip text="Grafico che mostra i dati relativi ai marchi venduti"/>
                    </Col>
                  </>
                }
                {/* ----------------------- gruppo di dati condizionale, visible solo se si è retailer ----------------------- */}

              </Row>
            </Checkbox.Group>
        
            {/* ----------------------- SOLO RISTORATORI: campo per ottenere i dati secondo la data lavoro ----------------------- */}
            {isRestaurant &&
              <Col span={24}>
                  <Divider>
                    <span className='m-2'> Utilizza la data lavoro </span>
                    <DiTooltip text="Tutti i dati verranno mostrati tenendo in considerazione che la tua giornata lavorativa finisca secondo quella impostata da te e non la data solare"/>
                  </Divider>
                  <Checkbox key={"data_filter"} checked={useDataFilter} onChange={changeDataFilter}>Utilizza data lavoro</Checkbox>
              </Col>
            }
            {/* ----------------------- SOLO RISTORATORI: campo per ottenere i dati secondo la data lavoro ----------------------- */}

        
            {/* ----------------------- filtri per cambiare statistiche rapide sull'ora corrente o tutto il giorno precedente ----------------------- */}
            <Divider>
              <span className='m-2'>Statistiche rapide fino all'ora corrente </span>
              <DiTooltip text="Con questa impostazione andrai a confrontare i dati di oggi con i giorni prcedenti fino all'orario corrente o confrontando tutto il giorno precedente con i dati ottenuti oggi fin'ora"/>
            </Divider>
            <Checkbox key={"use_current_time"} checked={useCurrentTime} onChange={changeFastStats}>Utilizza orario corrente</Checkbox>
            {/* ----------------------- filtri per cambiare statistiche rapide sull'ora corrente o tutto il giorno precedente ----------------------- */}

        
            {/* ----------------------- lista camerieri per filtrare i dati ----------------------- */}
            {isRestaurant &&
             <>
                <Divider>
                  <span className='m-2'> Seleziona cameriere </span>
                  <DiTooltip text="Tutti i dati verranno mostrati tenendo in considerazione solo il cameriere scelto (anche le statistiche rapide). Lascia vuoto per tutti i dati"/>
                </Divider>
                <Select
                  showSearch
                  placeholder="Seleziona Negozio"
                  optionFilterProp="children"
                  className='w-100'
                  allowClear
                  value={waiterCode}
                  onClear={()=>{
                    setWaiterCode("");
                    setWaiterName("");
                    setOpen(false);
                    closeNotificationWaiter();
                  }}
                  onChange={
                    (value, i) => {
                      closeNotificationWaiter();
                      setWaiterName(i.name);
                      setOpen(false);
                      setWaiterCode(value ?? "");
                      openNotificationWaiter(i.name);
                    }
                  }
                  filterOption={(input, option) =>
                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                  }
                  options={waitersList}
                />
             </>
            }
            {/* ----------------------- lista camerieri per filtrare i dati ----------------------- */}
          

            {transactionList && transactionList.length > 0 &&     
              <Checkbox.Group
                style={{
                  width: '100%',
                }}
                onChange={changeTransaction}
                defaultValue={JSON.parse(localStorage.getItem(LOCAL_TRANSACTION_TYPE))}
              >
                <Row>
                {/* ----------------------- filtra per tipi di movimenti ----------------------- */}
                  <Divider>
                    <span className='m-2'>Filtra i movimenti </span>
                    <DiTooltip text="Scegli quali movimenti vedere, in caso di multiselezione il reso verrà sottratto, selezionando solamente il reso, questo verrà addizionato"/>
                  </Divider>
                  {transactionList.map((e, index) => {
                    return(
                      <Col key={index} span={24}>
                        <Checkbox value={e.CodTipoMovimento} name={e.CodTipoMovimento}>{e.Descrizione}</Checkbox>
                      </Col>
                      )
                    })
                  }
                {/* ----------------------- filtra per tipi di movimenti ----------------------- */}
                </Row>
              </Checkbox.Group>
            }

        </Drawer>
     </>
  )
}
