const LOCAL_TRANSACTION_TYPE = "__di_dashboard_transaction_type";
const LOCAL_USE_CURRENT_TIME = "__di_dashboard_use_current_time";
const LOCAL_DASHBOARD_FILTERS = "__di_dashboard_filters";
const LOCAL_LAST_VERSION = "__di_dashboard_last_version";
const LOCAL_DATA_FILTER = "__di_dashboard_data_filter";
const LOCAL_ACCESS_TOKEN = "__access_token";
const LOCAL_USER_TYPE = "__di_user_type";

const LOCAL_WAITER_PRODUCTION_COLOR = "__di_waiterProductionColor";
const LOCAL_TOP_10_CATEGORIES_COLOR = "__di_top10CategoriesColor";
const LOCAL_SELLED_PRODUCT_COLOR = "__di_selledProductColor";
const LOCAL_TOP_10_PRODUCT_COLOR = "__di_top10ProductsColor";
const LOCAL_PAYMENT_METHOD_COLOR = "__di_paymentMethodColor";
const LOCAL_WAITER_EARN_COLOR = "__di_waiterEarnColor";
const LOCAL_BRANDS_COLOR = "__di_brandsColor";
const LOCAL_EARN_COLOR = "__di_earnColor";

const LOCAL_FAST_EARN_COMPARE_1 = "__di_fast_earn_compare_1";
const LOCAL_FAST_EARN_COMPARE_2 = "__di_fast_earn_compare_2";
const LOCAL_FAST_EARN_COMPARE_3 = "__di_fast_earn_compare_3";

const LOCAL_FAST_CUSTOMER_COMPARE_1 = "__di_fast_customer_compare_1";
const LOCAL_FAST_CUSTOMER_COMPARE_2 = "__di_fast_customer_compare_2";
const LOCAL_FAST_CUSTOMER_COMPARE_3 = "__di_fast_customer_compare_3";

const LOCAL_FAST_RECEIPT_COMPARE_1 = "__di_fast_receipt_compare_1";
const LOCAL_FAST_RECEIPT_COMPARE_2 = "__di_fast_receipt_compare_2";
const LOCAL_FAST_RECEIPT_COMPARE_3 = "__di_fast_receipt_compare_3";

const LOCAL_FAST_AVERAGE_RECEIPT_COMPARE_1 = "__di_fast_average_receipt_compare_1";
const LOCAL_FAST_AVERAGE_RECEIPT_COMPARE_2 = "__di_fast_average_receipt_compare_2";
const LOCAL_FAST_AVERAGE_RECEIPT_COMPARE_3 = "__di_fast_average_receipt_compare_3";

const LOCAL_DARK_MODE = "__di_dark_mode";


const LIGHT_MOBILE_HEADER = "rgb(255 255 255 / 53%)";
const DARK_MOBILE_HEADER = "rgb(41 41 41 / 53%)";
const DARK_BG_CONTAINER = "#292929";
const PRIMARY_COLOR = "#008833";
const DARK_BG_BODY = "#161515";
const DARK_TEXT = "#000";

const TOOLTIP_DIFF_TEXT = "Indica il giorno precedente da cui attingere i dati";

// ------------ NOMI DEI FILTRI ------------
const FILTERS_NAME = [
   "waitersProduction",
   "selledCategories",
   "top10Categories",
   "selledProduct",
   "top10Products",
   "paymentMethod",
   "openedTable",
   "receiptData",
   "brands",
   "earn",
];
// ------------ nomi di ogni widget di statistica che viene visualizzato o meno a seconda dei filtri, e la configurazione viene salvata nel local storage.
// ------------ Se non sono salvati, vengono inizializzati questi (lista completa per vederli tutti)
// ------------ NOMI DEI FILTRI ------------

const TAG_COLORS_PALETTE = [
   "geekblue",
   "magenta",
   "volcano",
   "purple",
   "orange",
   "green",
   "gold",
   "lime",
   "cyan",
   "blue",
   "red",
];


// ------------ API ENDPOINTS ------------

const ENDPOINT_BASE_PATH = "rest_gate/sales";

const ENDPOINT_PAYMENTS_DATA = `${ENDPOINT_BASE_PATH}/payments_data`;
const ENDPOINT_MOVEMENT_TYPE = `${ENDPOINT_BASE_PATH}/movement_type`;
const ENDPOINT_PERIOD_SALES = `${ENDPOINT_BASE_PATH}/period_sales`;
const ENDPOINT_WAITER_LIST = `${ENDPOINT_BASE_PATH}/waiter_list`;
const ENDPOINT_CANCELLED = `${ENDPOINT_BASE_PATH}/get_cancelled`;
const ENDPOINT_SOLD_ITEMS = `${ENDPOINT_BASE_PATH}/sold_items`;
const ENDPOINT_TIME_SLOTS = `${ENDPOINT_BASE_PATH}/time_slots`;
const ENDPOINT_DOC_TYPE = `${ENDPOINT_BASE_PATH}/doc_type`;
const ENDPOINT_BRANDS = `${ENDPOINT_BASE_PATH}/brands`;
const ENDPOINT_SHOPS = `${ENDPOINT_BASE_PATH}/shops`;

// ------------ API ENDPOINTS ------------


export {
   LOCAL_TOP_10_CATEGORIES_COLOR,
   LOCAL_WAITER_PRODUCTION_COLOR,
   LOCAL_PAYMENT_METHOD_COLOR,
   LOCAL_TOP_10_PRODUCT_COLOR,
   LOCAL_SELLED_PRODUCT_COLOR,
   LOCAL_WAITER_EARN_COLOR,
   LOCAL_BRANDS_COLOR,
   LOCAL_EARN_COLOR,
   
   LOCAL_DASHBOARD_FILTERS,
   LOCAL_USE_CURRENT_TIME,
   LOCAL_TRANSACTION_TYPE,
   LOCAL_ACCESS_TOKEN,
   LOCAL_LAST_VERSION,
   LOCAL_DATA_FILTER,
   LOCAL_USER_TYPE,

   LOCAL_FAST_EARN_COMPARE_1,
   LOCAL_FAST_EARN_COMPARE_2,
   LOCAL_FAST_EARN_COMPARE_3,

   LOCAL_FAST_CUSTOMER_COMPARE_1,
   LOCAL_FAST_CUSTOMER_COMPARE_2,
   LOCAL_FAST_CUSTOMER_COMPARE_3,

   LOCAL_FAST_RECEIPT_COMPARE_1,
   LOCAL_FAST_RECEIPT_COMPARE_2,
   LOCAL_FAST_RECEIPT_COMPARE_3,

   LOCAL_FAST_AVERAGE_RECEIPT_COMPARE_1,
   LOCAL_FAST_AVERAGE_RECEIPT_COMPARE_2,
   LOCAL_FAST_AVERAGE_RECEIPT_COMPARE_3,

   ENDPOINT_PAYMENTS_DATA,
   ENDPOINT_MOVEMENT_TYPE,
   ENDPOINT_PERIOD_SALES,
   ENDPOINT_WAITER_LIST,  
   ENDPOINT_SOLD_ITEMS,
   ENDPOINT_TIME_SLOTS, 
   ENDPOINT_CANCELLED,
   ENDPOINT_DOC_TYPE,
   ENDPOINT_BRANDS,
   ENDPOINT_SHOPS, 

   LOCAL_DARK_MODE,
   
   LIGHT_MOBILE_HEADER,
   DARK_MOBILE_HEADER,
   DARK_BG_CONTAINER,
   PRIMARY_COLOR,
   DARK_BG_BODY,
   DARK_TEXT,

   TAG_COLORS_PALETTE,
   FILTERS_NAME,

   TOOLTIP_DIFF_TEXT,
}