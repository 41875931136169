import React, { useEffect, useState } from 'react';

import { Navigate, Outlet, useNavigate } from 'react-router';

function PrivateRoutes(props) {

  const [accessToken, setAccessToken] = useState(sessionStorage.getItem("__access_token"));
  const [render, setRender] = useState(true);
  const navigation = useNavigate();

  return (
    accessToken ? <Outlet/> : <Navigate to="/login"/>
  );
}

export default PrivateRoutes;