import React from 'react';
import ReactDOM from 'react-dom/client';

import './index.scss';

import RoutesComponent from './Routes';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render( 
      <RoutesComponent/>
);
