import React, { useContext, useState } from 'react'

import { Badge, Button, Typography } from 'antd'

import { Context } from '../helpers/Context';
import { CloseCircleFilled, CloseOutlined } from '@ant-design/icons';

export default function ChangeLogTitle() {
   const { filterChangelog, showResetFilterChangelog, resetChangelogFilter } = useContext(Context);

   return (
      <div className='flex flex-wrap gap-20 align-center'>
         <Typography.Title className='m-0 mb-0' level={5}>Storico Rilasci</Typography.Title>
         <div className='flex'>
            <Button type='text' onClick={()=>filterChangelog("add")}><Badge dot status="success" className='cursor-pointer' > Aggiunta  </Badge></Button>
            <Button type='text' onClick={()=>filterChangelog("fix")}><Badge dot status="error"   className='cursor-pointer' > Fix       </Badge></Button>
            <Button type='text' onClick={()=>filterChangelog("mod")}><Badge dot status="warning" className='cursor-pointer' > Modifica  </Badge></Button>
         </div>
         {showResetFilterChangelog && <Button type='primary' size='small' icon={<CloseOutlined/>} onClick={resetChangelogFilter}>Tutto</Button>}
      </div>
  )
}