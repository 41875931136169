import React, { useEffect, useState } from 'react';

import { Col, Row, Empty, DatePicker, Tooltip } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';
import * as Unicons from '@iconscout/react-unicons';
import dayjs from 'dayjs';


import { ENDPOINT_PERIOD_SALES, ENDPOINT_SOLD_ITEMS, ENDPOINT_TIME_SLOTS, LOCAL_ACCESS_TOKEN } from '../../helpers/Globals';
import { todayEndDate, todayStartDate } from '../../helpers/DateUtils';
import TableReceipts from '../tables/TableReceipts';
import BarGrouped from '../charts/BarGrouped';
import { ApiCall } from '../../Backoffice';
import BarChart from '../charts/BarChart';
import { useParams } from 'react-router';

const { RangePicker } = DatePicker;

function SingleWaiterData(props) {

  const params = useParams();

  const [startDate, setStartDate] = useState(dayjs().format('YYYY-MM-DDT00:00:00'))
  const [endDate, setEndDate] = useState(dayjs().format('YYYY-MM-DDT23:59:59'))
  const [dailyBestSalesHour, setDailyBestSalesHour] = useState("00:00");
  const [dailyRecepeitsData, setDailyRecepeitsData] = useState({});
  const [totalProductSales, setTotalProductSales] = useState(0);
  const [totalDailyAmount, settotalDailyAmount] = useState(0);
  const accessToken = localStorage.getItem(LOCAL_ACCESS_TOKEN);
  const [dailyRecepeits, setDailyRecepeits] = useState(0);
  const [dailyProduct, setDailyProduct] = useState([]);
  const [dailySales, setDailySales] = useState([]);
  const [resetDate, setResetDate] = useState(0);
  const waiterId = params.id;
  
  // settare la data giornaliera
  useEffect(()=>{
    setStartDate(todayStartDate)
    setEndDate(todayEndDate)
  },[resetDate])
  
  //chiamata per ricevere i dati venduto prodotti per periodo
  //ricevere il csv aggiungere alla richiesta alla fine &produce_csv=1
  useEffect(()=>{
    
    (async () => {
      const json = await ApiCall(`${ENDPOINT_SOLD_ITEMS}?date=${startDate}&end_date=${endDate}&waiter=${waiterId}`, "GET", `${accessToken}`, null)
      if(json){
      
        var totalProductQuantity = 0;
        var totalDailyAmount = 0;

        for (let i = 0; i < json.length; i++) {

         totalProductQuantity = totalProductQuantity + json[i]["quantity"];
         totalDailyAmount = totalDailyAmount + json[i]["amount"];

          //modifica delle chiavi prodotto in etichette per i grafici
          json[i]["Prodotto"] = json[i]["description"];
          delete json[i]["description"];

          //json[i]["Incasso medio"] = json[i]["average"];
          //delete json[i]["average"];

          json[i]["Incasso totale"] = json[i]["amount"];
          delete json[i]["amount"];

          json[i]["Quantità vendute"] = json[i]["quantity"];
          delete json[i]["quantity"];

          //modifica dei dati inutilizzati, commentare per usarli
          delete json[i]["code"];
          delete json[i]["average"];

         }
         setDailyProduct(json);
         setTotalProductSales(totalProductQuantity);
         settotalDailyAmount(totalDailyAmount);
      }
    })(); 
  },[startDate]);

  //chiamata per ricevere i dati del venduto su orario giornaliero

  useEffect(()=>{
    
    (async () => {
      const json = await ApiCall(`${ENDPOINT_TIME_SLOTS}?date=${startDate}&end_date=${endDate}&waiter=${waiterId}`, "GET", `${accessToken}`, null)
      if(json){
        var xAxis = Object.keys(json);
        var yAxis = Object.values(json);
    
        var tempData = [];
        var bestSalesHour = {};

        for (const i in json) {
          tempData.push({
            "orari":i,
            "incasso":json[i].toFixed(2)
          })
        };
        
        setDailySales(tempData);
        setDailyBestSalesHour(tempData.sort((a,b) => b.incasso - a.incasso)[0].orari)
      
      }
    })();


  },[startDate]);

  //chiamata per ricevere i dati del venduto per periodo || ricevute

  useEffect(()=>{
    (async () => {

      const json = await ApiCall(`${ENDPOINT_PERIOD_SALES}?date=${startDate}&end_date=${endDate}&waiter=${waiterId}`, "GET", `${accessToken}`, null)
      if(json){
        var recepeitsData = json.sales;
        var counter = 0;

        for (const i in json.sales) {
            recepeitsData[i]["key"] = recepeitsData[i]["bill_id"];
            delete recepeitsData[i]["bill_id"];
            recepeitsData[i]["children"] = recepeitsData[i]["lines"];
            delete recepeitsData[i]["lines"];

            for (let n in recepeitsData[i]["children"]) {
              counter += 1;
              recepeitsData[i]["children"][n]["key"] = counter;
              delete recepeitsData[i]["code"];
            }
        }
        setDailyRecepeits(json.sales.length);
        setDailyRecepeitsData(recepeitsData)
      }
    })();

  },[startDate]);


  // gestione del range date
  const onRangeChange = (dates, dateStrings) => {
    if (dates) {

      var newStartDate = dateStrings[0].replaceAll("/", "-").replaceAll(/\s/g,'T');
      var newEndDate = dateStrings[1].replaceAll("/", "-").replaceAll(/\s/g,'T');
      setStartDate(newStartDate);
      setEndDate(newEndDate);

    } else {
      setResetDate(1);
    }
  };
  const rangePresets = [
    {
      label: 'Ultimi 7 giorni',
      value: [dayjs().add(-7, 'd'), dayjs()],
    },
    {
      label: 'Ultimi 14 giorni',
      value: [dayjs().add(-14, 'd'), dayjs()],
    },
    {
      label: 'Ultimi 30 giorni',
      value: [dayjs().add(-30, 'd'), dayjs()],
    },
    {
      label: 'Ultimi 90 giorni',
      value: [dayjs().add(-90, 'd'), dayjs()],
    },
  ];


  return (
    <div className='p-25'>
     
      {/* prima riga */}
       <Row gutter={[20, 20]} className="mb-20">
        <Col span={24} sm={{span:24}} md={{span:24}} xl={{span:12}} xxl={{span:6}}>
         <Row gutter={[15, 15]} className="di-card h-100" justify="center" align="middle">
          <div className='flex column align-center justify-center gap-10'>
            <div className='flex gap-10 color-grey fs-8'>
              <div>Intervallo di tempo dei dati</div>
              <Tooltip color="#008833" className="cursor-pointer color-primary di-chart-tooltip fs-18" title="Intervallo temporale dei dati a schermo (default giornaliero)">
                <QuestionCircleOutlined className=''/>
              </Tooltip>
            </div>
            <RangePicker
              placeholder={["Inizio","Fine"]}
              className='mb-10'
              presets={rangePresets}
              showTime
              format="YYYY/MM/DD HH:mm:ss"
              onChange={onRangeChange}
            />
          </div>
         </Row>
        </Col>
        <Col span={12} md={{span:8}} xl={{span:12}}  xxl={{span:6}}>
         <Row gutter={[15, 15]} className="di-card h-100" justify="center" align="middle">
           <Col xs={{span:24}} xl={{span:6}}  align="center">
             <div>
               <Unicons.UilPizzaSlice className="color-primary"/>
             </div>
           </Col>
           <Col xs={{span:24}} xl={{span:14}} align="center">
             <div>Totale prodotti venduti</div>
           </Col>
           <Col xs={{span:24}} xl={{span:4}} align="center">
               {totalProductSales}
           </Col>
         </Row>
        </Col>
        <Col span={12} md={{span:8}} xl={{span:12}}  xxl={{span:6}}>
         <Row gutter={[15, 15]} className="di-card h-100" justify="center"  align="middle">
           <Col xs={{span:24}} xl={{span:4}} align="center">
             <div>
               <Unicons.UilCoins className="color-primary"/>
             </div>
           </Col>
           <Col xs={{span:24}} xl={{span:14}} align="center">
             <div>Totale incasso giornaliero</div>
           </Col>
           <Col xs={{span:24}} xl={{span:6}} align="center" className='overflow-hidden white-space-nowrap'>
             {totalDailyAmount.toFixed(2)} €
           </Col>
         </Row>
        </Col>
        <Col span={24} md={{span:8}} xl={{span:12}}  xxl={{span:6}}>
         <Row gutter={[15, 15]} className="di-card h-100" justify="center" align="middle">
           <Col xs={{span:24}} xl={{span:4}} align="center">
             <div>
               <Unicons.UilClock className="color-primary"/>
             </div>
           </Col>
           <Col xs={{span:24}} xl={{span:14}} align="center">
             <div>Miglior orario di vendita</div>
           </Col>
           <Col xs={{span:24}} xl={{span:6}} align="center">
             {dailyBestSalesHour}
           </Col>
         </Row>
        </Col>
       </Row>
      {/* prima riga */}

      {/* dati scontrini */}
      <Row gutter={[15, 15]} className="mb-20">
       <Col span={24}>
        { 
            dailyProduct.length !== 0 
            ?
              <div className="di-card h-100" id="di-daily-product-chart">
                <BarGrouped data={dailyProduct}/> 
              </div> 
            :
              <div className="di-card h-100">
                <Empty description="Sembra non ci siano dati per il momento"/>
              </div>
          }
       </Col>
      </Row>
      {/* dati scontrini */}

      {/* dati orari del giorno */}
      <Row gutter={[15, 15]} className="mb-20">
        <Col xs={{span:24}} xl={{span:12}}>
          { 
            dailySales.length !== 0 
            ?
              <div className="di-card">
                <BarChart data={dailySales}/> 
              </div> 
            :
              <div className="di-card">
                <Empty description="Sembra non ci siano dati per il momento"/>
              </div>
          }
        </Col>
        {/* dati orari del giorno */}

        {/* dati sui prodotti  */}
        <Col xs={{span:24}} xl={{span:12}}>
        { 
            dailyRecepeitsData.length !== undefined
            ?
              // <DiTable data={dailyRecepeitsData}/>
              <div className="di-card h-100">
                <TableReceipts data={dailyRecepeitsData}/>
              </div>
            :
            <div className="di-card h-100">
              <Empty description="Sembra non ci siano dati per il momento"/>
            </div>
          }
        </Col>
        {/* dati sui prodotti  */}

      </Row>
      
    </div>
  );
}

export default SingleWaiterData;