import React, { useContext, useState } from 'react'

import { InfoCircleFilled, InfoOutlined } from '@ant-design/icons';
import { FloatButton, Tour } from 'antd'

import { Context } from '../../helpers/Context';
import { LOCAL_LAST_VERSION } from '../../helpers/Globals';

export default function DiDashboardTour() {
   
    const [open, setOpen] = useState(false);
    const [showDot, setShowDot] = useState(localStorage.getItem(LOCAL_LAST_VERSION) && localStorage.getItem(LOCAL_LAST_VERSION) == "1.2.4" ? true : false)
   
   const {
      refShowPercentage,
      refChangeDay,
      refFastStat,
      refDarkMode,
      refVersion,
      refColors,
      refFilter,
      refRange,
      refCsv,
   } = useContext(Context);

   const steps = [
    {
      title: 'Statistica Rapida',
      description: 'Le statistiche rapide mostrano dati della giornata odierna, confrontati con giorni precedenti, per una rapida occhiata.',
      target: () => refFastStat.current,
    },
    {
      title: 'Mostra Percentuale',
      description: 'Questo switcher ti permetterà di vedere il confronto dei tuoi dati in valore numerico o percentuale',
      target: () => refShowPercentage.current,
    },
    {
      title: 'Confronto Statistiche',
      description: 'Clicca qui per impostare i giorni con cui vuoi confrontare i dati della giornata odierna',
      target: () => refChangeDay.current,
    },
    {
      title: 'Imposta il range di date',
      description: 'Scegli per ogni grafico, il range di tempo da interrogare, se non impostato mostrerà la giornata odierna. Massimo range 1 anno, ricorda: periodi lunghi, attese lunghe!',
      target: () => refRange.current,
    },
    {
      title: 'Scegli i tuoi colori!',
      description: 'Scegli per ogni grafico la tua palette colori preferita dalla nostra selezione.',
      target: () => refColors.current,
    },
    {
      title: 'Download in CSV',
      description: 'Una volta scelto il range di tempo, puoi scaricare i dati del grafico in CSV',
      target: () => refCsv.current,
    },
    {
      title: 'Filtra i tuoi dati',
      description: 'Scegli quali grafici vedere nella tua bacheca, ed imposta come interrogare i tuoi dati',
      target: () => refFilter.current,
    },
    {
      title: 'Scegli il tuo tema',
      description: 'Imposta la tua dashboard con il tema scuro o chiaro e scegli ciò che preferisci!',
      cover: (
      <img
         alt="tour.png"
         src="/dylogapp-dark-light.jpg"
      />
      ),
      target: () => refDarkMode.current,
    },
   ];
   
   return (
     <>
         <FloatButton
            icon={<InfoOutlined />}
            onClick={() => setOpen(true)}
            style={{ marginBottom: "65px" }}
            badge={{
              dot: showDot,
            }}
         />
         <Tour
            open={open}
            onClose={() => setOpen(false)}
            steps={steps}
         />
     </>
  )
}
