import React, { useEffect, useState } from 'react';

import { useNavigate } from 'react-router';

import { Col, Row, Empty, DatePicker, Tooltip, Select } from 'antd';
import { QuestionCircleOutlined, } from '@ant-design/icons';
import dayjs from 'dayjs';

import { ENDPOINT_PERIOD_SALES } from '../../helpers/Globals';
import { colorsPalette } from '../../helpers/ColorsPalette';
import DashboardLayout from '../DashboardLayout';
import { ApiCall } from '../../Backoffice';

const { RangePicker } = DatePicker;


function formatEuro(number){
  return Number(number.toFixed(2)).toLocaleString("es-ES", {minimumFractionDigits: 2});
}

function Operators(props) {

  //dati utente
  const [userType] = useState(sessionStorage.getItem("__di_user_type"));
  const accessToken = sessionStorage.getItem("__access_token");

  //impostazione dati dei resi
  const [returnsColor, setReturnsColor] = useState(localStorage.getItem("__di_returnsColor") ?? "nivo");
  const [returnsSd, setReturnsSd] = useState(dayjs().format('YYYY-MM-DDT00:00:00'));
  const [returnsEd, setReturnsEd] = useState(dayjs().format('YYYY-MM-DDT23:59:59'));
  const [loadingReturns, setLoadingReturns] = useState(false);
  const [resetReturnsDate, setResetReturnsDate] = useState(0);
  const [returns, setReturns] = useState([]);

  const navigate = useNavigate();
  
  //presets per tutti i calendari
  const rangePresets = [
    {
      label: 'Ultimi 7 giorni',
      value: [dayjs().add(-7, 'd'), dayjs()],
    },
    {
      label: 'Ultimi 14 giorni',
      value: [dayjs().add(-14, 'd'), dayjs()],
    },
    {
      label: 'Ultimi 30 giorni',
      value: [dayjs().add(-30, 'd'), dayjs()],
    },
    {
      label: 'Ultimi 90 giorni',
      value: [dayjs().add(-90, 'd'), dayjs()],
    },
  ];

//chiamata per ricevere i dati dei resi
  useEffect(()=>{
    var startDate = dayjs().format('YYYY-MM-DDT00:00:00');
    var endDate = dayjs().format('YYYY-MM-DDT23:59:59');

    (async () => {

      const json = await ApiCall(`${ENDPOINT_PERIOD_SALES}?date=${startDate}&end_date=${endDate}&type_sale=RC`, "GET", `${accessToken}`, null)
      if(json){
        console.log("resi",json.sales)
      }
    })();

  },[]);

  // gestione del range date per i resi
  const returnsRangeDate = (dates, dateStrings) => {

    if (dates) {
      var newStartDate = dateStrings[0].replaceAll("/", "-").replaceAll(/\s/g,'T');
      var newEndDate = dateStrings[1].replaceAll("/", "-").replaceAll(/\s/g,'T');
      setReturnsSd(newStartDate);
      setReturnsEd(newEndDate);
    } else {
      setResetReturnsDate(resetReturnsDate + 1);
    }
  };

  return (
    <DashboardLayout>
      <div className="di-card h-100">
        <Row>
          <Col span={24}>
            <div className='flex column align-center space-between gap-10'>
              <div className='flex gap-10 color-grey fs-8'>
                <div>Camerieri</div>
                <Tooltip color="#008833" className="cursor-pointer color-primary di-chart-tooltip fs-18" title="Le top 10 categorie di prodotti più venduti per l'intervallo di tempo stabilito">
                  <QuestionCircleOutlined className=''/>
                </Tooltip>
              </div>
                <div className='flex align-center gap-10 space-between w-100 flex-wrap'>
                  <RangePicker
                    placeholder={["Inizio","Fine"]}
                    presets={rangePresets}
                    className='mobile-w-100'
                    showTime
                    format="YYYY/MM/DD HH:mm:ss"
                    onChange={returnsRangeDate}
                  />
                  <Select
                    showSearch
                    placeholder="Seleziona Colore"
                    optionFilterProp="children"
                    className='mobile-w-100'
                    defaultValue={returnsColor}
                    onChange={
                      (value)=>{
                        setReturnsColor(value);
                        localStorage.setItem("__di_returnsColor",value)
                      }
                    }
                    filterOption={(input, option) =>
                      (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                    }
                    options={colorsPalette}
                  />
                </div>
            </div>
          </Col>
        </Row>
      </div>
    </DashboardLayout>
  );
}

export default Operators;