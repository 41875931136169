import { useContext } from 'react';

import { ResponsiveBar } from '@nivo/bar'

import { Context } from '../../helpers/Context';
import { DARK_BG_CONTAINER } from '../../helpers/Globals';

const BarChart = ({ data, colors, layout, xIndex, yIndex, suffix, margin }) => {
       
    const { darkMode } = useContext(Context);

    return (
       <div className='di-chart-wrapper'>
         <div className='di-chart' style={{minHeigth:"600px", height: data.length + 50 + "0px"}}>
             <ResponsiveBar
                 data= {data}
                 keys={[yIndex]}
                 indexBy={xIndex}
                 margin={
                    !margin && window.innerWidth < 1000 ?  { top: 20, right: 10, bottom: 20, left: 30 } : 
                    margin ? margin : 
                    { top: 20, right: 50, bottom: 50, left: 60 }
                 }
                 valueScale={{ type: 'linear' }}
                 indexScale={{ type: 'band', round: true }}
                 colors={{ scheme : colors}}
                 layout={layout}
                 valueFormat={v => !suffix ? v : v + suffix }
                 axisBottom={ window.innerWidth < 1000 ?
                        null
                    :
                        {
                            tickSize: 5,
                            tickPadding: 5,
                            tickRotation: 0,
                            //legend: xIndex,
                            legendPosition: 'middle',
                            legendOffset: 32
                        }
                 }
                 axisLeft={{
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                    //legend: yIndex,
                    legendPosition: 'middle',
                    legendOffset: -50,
                    renderTick: ({
                        opacity,
                        textAnchor,
                        textBaseline,
                        textX,
                        textY,
                        theme,
                        value,
                        x,
                        y
                      }) => {
                        return (
                          <g
                            transform={`translate(${x},${y})`}
                            style={{ opacity }}
                          >
                            <text
                                alignmentBaseline={textBaseline}
                                textAnchor={textAnchor}
                                transform={`translate(${textX},${textY})`}
                                fill={darkMode ? "#fff" : "#000"}
                            >
                                {value.toString().slice(0,10)}
                            </text>
                          </g>
                        )
                      }
                 }}
                 labelSkipWidth={12}
                 labelSkipHeight={12}
                 labelTextColor={{
                     from: 'color',
                     modifiers: [
                         [
                             'darker',
                             1.6
                         ]
                     ]
                 }}
                 legends={[
                     {
                         dataFrom: 'keys',
                         anchor: 'top-left',
                         direction: 'column',
                         justify: false,
                         translateX: -30,
                         translateY: -50,
                         itemsSpacing: 2,
                         itemWidth: 100,
                         itemHeight: 20,
                         itemDirection: 'left-to-right',
                         itemOpacity: 0.85,
                         symbolSize: 20,
                         effects: [
                             {
                                 on: 'hover',
                                 style: {
                                     itemOpacity: 1
                                 }
                             }
                         ]
                     }
                 ]}
                role="application"
                    
                theme={{
                    axis: {
                        ticks: {
                            text: {
                                fill:darkMode ? "#fff" : "000",
                                outlineColor:darkMode ? "#fff" : "000"
                            },
                        },
                        legend: {
                            text: {
                                fill:darkMode ? "#fff" : "000"
                            }                                        
                        }
                    },
                    tooltip: {
                        container: {
                            background: darkMode ? DARK_BG_CONTAINER : "#fff",
                        }
                    }
                }}
                    


             />
         </div>
       </div>
    )
};

export default BarChart;