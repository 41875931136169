import React, { useContext, useEffect, useState } from 'react';

import { Col, Row, } from 'antd';

import { FILTERS_NAME, LOCAL_DASHBOARD_FILTERS, LOCAL_USER_TYPE, } from '../../helpers/Globals';
import DashboardLayout from '../DashboardLayout';
import { Context } from '../../helpers/Context';

import DisplayFastDailyAverageReceipt from '../data_display/fast_stats/DisplayFastDailyAverageReceipt';
import DisplayFastDailyOpenedTable from '../data_display/fast_stats/DisplayFastDailyOpenedTable';
import DisplayFastDailyCustomer from '../data_display/fast_stats/DisplayFastDailyCustomer';
import DisplayFastDailyReceipt from '../data_display/fast_stats/DisplayFastDailyReceipt';
import DisplayFastDailyEarn from '../data_display/fast_stats/DisplayFastDailyEarn';
import DisplayWaiterProduction from '../data_display/DisplayWaiterProduction';
import DisplaySelledCategories from '../data_display/DisplaySelledCategories';
import DisplayTop10Categories from '../data_display/DisplayTop10Categories';
import DisplaySelledProduct from '../data_display/DisplaySelledProduct';
import DisplayPaymentMethod from '../data_display/DisplayPaymentMethod';
import DisplayTop10Products from '../data_display/DisplayTop10Products';
import DisplayOpenedTables from '../data_display/DisplayOpenedTables';
import DisplayReceiptData from '../data_display/DisplayReceiptData';
import DisplayDailyEarn from '../data_display/DisplayDailyEarn';
import DisplayBrands from '../data_display/DisplayBrands';
import DiDashboardTour from '../tours/DiDashboardTour';


if(!localStorage.getItem(LOCAL_DASHBOARD_FILTERS)){
  localStorage.setItem(LOCAL_DASHBOARD_FILTERS, JSON.stringify(FILTERS_NAME))
} //setto tutti i filtri che si attivano quando la prima volta apri e non hai mai filtrato nulla


function Dashboard(props) {
  
  const [activeFilters, setActiveFilters] = useState(JSON.parse(localStorage.getItem(LOCAL_DASHBOARD_FILTERS)));

  // ---- variabili dal context -----
  const {
    setIsRestaurant,
    isRestaurant,
    openedTable,

    changeFilter,
  } = useContext(Context);
  // ---- variabili dal context -----

  
  useEffect(() => {
    setIsRestaurant(sessionStorage.getItem(LOCAL_USER_TYPE) === "restaurant" ? true : false)
  }, [])
  
  useEffect(() => {
   setActiveFilters(JSON.parse(localStorage.getItem(LOCAL_DASHBOARD_FILTERS)))
  }, [changeFilter]) //aggiorno il dom al salvataggio dei filtri nel local storage
  
  return (
    <DashboardLayout>
      <section className='di-ardi-dashboard'>
        {/* prima riga */}
        <Row gutter={[20, 20]} className="mb-20">

          {/* statistica rapida totale incasso */}
          <Col 
            id='fastStats'
            span={24} 
            md={24} 
            lg={isRestaurant ? 12 : 24} 
            xl={isRestaurant ? 12 : 24} 
            xxl={isRestaurant ? 6 : 12}
          >
           <DisplayFastDailyEarn/>
          </Col>

          {/* statistica rapida clienti serviti */}
          {isRestaurant &&
           <Col 
            span={24} 
            md={24} 
            lg={12} 
            xl={12} 
            xxl={isRestaurant ? 6 : 8}
           >
            <DisplayFastDailyCustomer/>
           </Col>
          } 

          {/* statistica rapida documenti emessi */}
          <Col 
            span={24} 
            md={24} 
            lg={isRestaurant ? 12 : 24}  
            xl={isRestaurant ? 12 : 24} 
            xxl={isRestaurant ? 6 : 12}
          >
            <DisplayFastDailyReceipt/>
          </Col>

          {/* statistica rapida tavoli aperti */}
         { isRestaurant &&
            <Col span={24} md={24} lg={12} xl={12} xxl={6}>
              <DisplayFastDailyOpenedTable/>
            </Col>
          }
        </Row>
        {/* prima riga */}
          
        {/* riga degli scontrini medi (non ci sono setState tutti i clacoli vengono fatti con le variabili già calcolate degli scontrini e guadagni) */}

        <Row gutter={[15,15]}>
          <Col span={24} className='mb-20'>
            <DisplayFastDailyAverageReceipt/>
          </Col>
        </Row>
        {/* ---------------- riga degli scontrini medi ---------------- */}

        {/* ----------------  top10 prodotti e categorie e metodi di pagmaento ---------------- */}
          <Row id='top10' className="mb-20" gutter={[15,15]}>

            {activeFilters.indexOf("top10Products") !== -1 &&
              <Col span={24} xl={24} xxl={activeFilters.indexOf("top10Categories") !== -1 ? 12 : 24}>
                <DisplayTop10Products/>
              </Col>
            }
            {activeFilters.indexOf("top10Categories") !== -1 &&
              <Col span={24} xl={24} xxl={activeFilters.indexOf("top10Products") !== -1 ? 12 : 24}>
                <DisplayTop10Categories/>
              </Col>
            }
            {activeFilters.indexOf("paymentMethod") !== -1 &&
              <Col
                id='paymentMethod'
                span={24}
                xl={24}
                xxl={
                  isRestaurant ? 24 : 
                  activeFilters.indexOf("brands") !== -1 ? 12 : 24
                }
              >
                <DisplayPaymentMethod/>
              </Col>
            }
            {!isRestaurant && activeFilters.indexOf("brands") !== -1 &&
              <Col span={24} xl={24} xxl={activeFilters.indexOf("paymentMethod") !== -1 ? 12 : 24}>
                <DisplayBrands/>
              </Col>
            }
          </Row>
        {/* ----------------  top 10 prodotti, categorie e dati metodi di pagamento ----------------  */}

        {/* ---------------- dati prodotti venduti (selled product) ---------------- */}
        {activeFilters.indexOf("selledProduct") !== -1 &&
          <Row id='selledProduct' className="mb-20" gutter={[15,15]}>
            <Col span={24}>
              <DisplaySelledProduct/>
            </Col>
          </Row>
        }
        {/* ----------------  dati prodotti venduti (selled product) ---------------- */}
  
        {/* ---------------- riga degli incassi ---------------- */}
        {activeFilters.indexOf("earn") !== -1 &&
          <Row id="earn" className="mb-20" gutter={[15,15]}>
            <Col span={24}>
                <DisplayDailyEarn/>
            </Col>
          </Row> 
        }
        {/* ---------------- riga degli incassi ---------------- */}

        {/* ---------------- righe delle tabelle ristorazione ----------------*/}
        {isRestaurant &&
          <>
            <Row id="tables" className="mb-20" gutter={[15,15]}>
              {activeFilters.indexOf("selledCategories") !== -1 && 
                <Col span={24} xl={24} xxl={activeFilters.indexOf("waitersProduction") !== -1 ? 12 : 24} >
                  <DisplaySelledCategories/>
                </Col>
              }
              {activeFilters.indexOf("waitersProduction") !== -1 &&
                <Col span={24} xl={24} xxl={activeFilters.indexOf("selledCategories") !== -1 ? 12 : 24} >
                  <DisplayWaiterProduction/>
                </Col>
              }
            </Row>
          
            <Row className="mb-20" gutter={[15,15]}>
              {activeFilters.indexOf("openedTable") !== -1 && openedTable &&
                <Col span={24} xl={24} xxl={activeFilters.indexOf("receiptData") !== -1 ? 12 : 24} >
                  <DisplayOpenedTables/>
                </Col>
              }
              {activeFilters.indexOf("receiptData") !== -1 &&
                <Col span={24} xl={24} xxl={activeFilters.indexOf("openedTable") !== -1 ? 12 : 24} >
                  <DisplayReceiptData/>
                </Col>
              }
            </Row>
         </>
        }
        {/* ---------------- righe delle tabelle ristorazione ---------------- */}


       <DiDashboardTour />
        
      </section>
    </DashboardLayout>
  );
}

export default Dashboard;